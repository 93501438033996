import graphQLClient, { GraphQLCatch, LogReturn } from '@Api/graphQLClient';
import { ISurveysApi } from '@Api/index';
import { caseStatus } from '@Api/questionnaires';
import { Survey, SurveyWithResults } from '@Configs/schemas/Case/Survey';
import surveysAdapter from '@Api/surveys/surveys.adapter';
import { SurveyFields } from '@Utils/helpers/Survey';
import Case from '@Configs/schemas/Case/Case';
import { Permissions } from '../../app/configs/schemas/Permissions';

@LogReturn()
@GraphQLCatch()
class SurveysApiGraphQL implements ISurveysApi {
	async duplicateSurveyToSubject(surveyId: number, subjectId: number) {
		const res = await graphQLClient.DuplicateCaseToSubject({
			caseId: surveyId,
			subjectId,
		});

		return res.duplicateCaseToSubject.id;
	}
	async getSurveyResultsById(surveyId: string): Promise<SurveyWithResults> {
		const { getCaseByID: _survey } = await graphQLClient.GetSurveyResults({
			surveyId: +surveyId,
		});

		const survey = surveysAdapter.adaptSurveyWithResults(_survey);

		return survey;
	}
	async updateSurvey(data: SurveyFields): Promise<string> {
		const mappedData = surveysAdapter.surveyFieldsToGraphQLUpdate(data);

		const { putCase } = await graphQLClient.UpdateSurvey({
			data: mappedData,
			id: +data.id!,
		});
		console.log(
			'🚀 ~ file: index.ts ~ line 20 ~ SurveysApiGraphQL ~ updateSurvey ~ putCase',
			putCase
		);
		return putCase.id;
	}
	async deleteSurveys(ids: number[]): Promise<void> {
		await graphQLClient.DeleteSurveys({ ids });
	}
	async duplicateSurvey(caseId: number): Promise<string> {
		const { duplicateCase } = await graphQLClient.DuplicateSurvey({ caseId });
		return duplicateCase.id;
	}
	async getSurveyById(id: string): Promise<Survey> {
		const { getCaseByID: survey } = await graphQLClient.GetSurveyById({
			id: +id,
		});
		const mappedData: Survey = {
			id: survey.id.toString(),
			description: survey.description ?? 'DESCRIPCION DE LA ENCUESTA',
			dateFrom: survey.date_from,
			dateTo: survey.date_to,
			name: survey.name,
			subjectId: survey.subject.id.toString(),
			hasResponses: survey.steps?.some((step) => step.step_results?.length),
			questions: survey.steps?.map((step) => ({
				id: step.id.toString(),
				answerType: Case.getStepTypeNameById(step.step_type.id),
				title: step.title,
				order: 0,
				created_at: step.created_at,
				openField: step.open_field ?? false,
				options:
					step.step_options?.map((option) => ({
						id: option.id.toString(),
						text: option.title,
						created_at: option.created_at,
					})) ?? [],
			})),
		};
		console.log('GetSurveyById', mappedData);
		return mappedData;
	}
	async postSurveyDraft(data: SurveyFields): Promise<string> {
		const mappedData = surveysAdapter.surveyFieldsToGraphQL(data);
		const { postCase } = await graphQLClient.PostSurvey({
			data: { ...mappedData, caseStatus: caseStatus.Draft },
		});
		return postCase.id;
	}

	async postSurvey(data: SurveyFields): Promise<string> {
		const mappedData = surveysAdapter.surveyFieldsToGraphQL(data);
		const { postCase } = await graphQLClient.PostSurvey({ data: mappedData });
		return postCase.id;
	}

	async getSurveysBySubjectId(
		subjectId: number
	): Promise<{ data: Survey[]; permissions: Permissions }> {
		const {
			getSurveysBySubject: { data, globalPermissions },
		} = await graphQLClient.GetSurveysBySubject({
			caseType: 'survey',
			subjectId,
		});
		const mappedData =
			data?.map((d) => ({
				...d,
				description: d.description ?? 'DESCRIPCION DE LA ENCUESTA',
				subjectId: subjectId.toString(),
				results: {
					responseRate: d.results?.responseRate
						? d.results?.responseRate * 100
						: 0,
					studentAmmount: d.results?.responseAmmount ?? 0,
				},
			})) ?? [];
		return {
			data: mappedData,
			permissions: (globalPermissions ?? []) as Permissions,
		};
	}
}

export default new SurveysApiGraphQL();
