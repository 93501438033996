import { IQuestionnairesApi } from '@Api/index';
import graphQLClient, { GraphQLCatch, LogReturn } from '@Api/graphQLClient';
import { QuestionnaireFields } from '@Utils/helpers/Questionnaire';
import { CreateCaseDto, CreateFrom, UpdateCaseDto } from '@Api/generated';
import {
	Questionnaire,
	QuestionnaireWithResults,
} from '@Configs/schemas/Case/Questionnaire';
import { CaseStatusType } from '@Configs/schemas/Case/Case';
import {
	createAdaptedQuestionnaire,
	createAdaptedQuestionnaireStats,
	createGraphQLAdaptedQuestionnaire,
	createGraphQLAdaptedQuestionnaireUpdate,
} from '@Api/questionnaires/questionnaires.adapters';
import { Nullable } from '@Utils/types';
import User from '@Configs/schemas/User/User';
import axios from 'axios';
import restClient from '../restClient';
import { Permissions } from '../../app/configs/schemas/Permissions';

export const caseStatus = {
	Active: 1,
	Draft: 2,
	Trash: 3,
} as const;

export const caseTypes = {
	Questionnaire: 'questionnaire',
	Survey: 'survey',
} as const;

@LogReturn()
@GraphQLCatch()
class QuestionnairesApiGraphQL implements IQuestionnairesApi {
	async duplicateQuestionnaireToSubject(caseId: number, subjectId: number) {
		const res = await graphQLClient.DuplicateCaseToSubject({
			caseId,
			subjectId,
		});

		return res.duplicateCaseToSubject.id;
	}
	async downloadQuestionnairesReport(subjectId: string): Promise<string> {
		const res = await restClient.get('/reports/subject/' + subjectId);
		return res.data;
	}
	async getQuestionnairesBySubjectIdDashboard(subjectId: string): Promise<{
		id: string;
		students: {
			id: string;
			name: string;
			email: string;
			photo?: string;
		}[];
		questionnaires: QuestionnaireWithResults[];
	}> {
		const { getCasesBySubjectToDashboard: questionnaires_ } =
			await graphQLClient.GetCasesBySubjectIdToDashboard({
				subjectId: +subjectId,
			});
		if (
			!questionnaires_ ||
			!questionnaires_[0] ||
			!questionnaires_[0].subject
		) {
			return {
				id: '',
				students: [],
				questionnaires: [],
			};
		}
		const questionnaires = questionnaires_
			.filter((el) => el.caseType.id === '1')
			.map(createAdaptedQuestionnaireStats)
			.map((questionnaire, index) => ({
				...questionnaire,
				results: {
					...questionnaires_[index].results,
					studentAmount: questionnaires_[index].results?.studentAmount ?? 0,
					responseRate: questionnaires_[index].results?.responseRate ?? 0,
				},
			}));

		return {
			id: questionnaires_[0].subject.id,
			students:
				questionnaires_[0].subject.studentSubjects?.map(({ student }) => ({
					id: student?.id ?? '',
					name: User.getFullName(student?.user),
					email: student?.user.email ?? '',
					//TODO Add photo when it will be available
					//photo: student?.user.photo ?? '',
				})) ?? [],
			questionnaires,
		};
	}
	async getQuestionnaireStatus(id: string): Promise<Nullable<string>> {
		const { getQuestionnaireStatus } =
			await graphQLClient.GetQuestionnaireNotifications({
				questionnaireId: +id,
			});

		return getQuestionnaireStatus?.status;
	}
	async getQuestionnaireStatistics(
		id: string
	): Promise<QuestionnaireWithResults> {
		const { getCaseByID } = await graphQLClient.GetQuestionnaireStatistics({
			questionnaireId: +id,
		});

		const data = createAdaptedQuestionnaireStats(getCaseByID);

		return data;
	}

	async duplicateQuestionnaire(caseId: number): Promise<number> {
		const { duplicateCase } = await graphQLClient.DuplicateCase({
			caseId,
		});

		return +duplicateCase.id;
	}

	async updateQuestionnaire(
		id: string,
		data: QuestionnaireFields
	): Promise<string> {
		const mappedData = createGraphQLAdaptedQuestionnaireUpdate(data);
		const { putCase } = await graphQLClient.PutCase({
			id: +id,
			data: mappedData,
		});
		return putCase.id;
	}

	async putCase(id: number, data: UpdateCaseDto) {
		const { putCase } = await graphQLClient.PutCase({
			id,
			data,
		});
		return putCase.id;
	}

	async deleteQuestionnaires(ids: number[], caseType: string): Promise<void> {
		await graphQLClient.DeleteQuestionnaires({
			ids,
			caseType,
		});
	}

	async getQuestionnaireById(id: string): Promise<Questionnaire> {
		const { getCaseByID } = await graphQLClient.GetCaseById({
			questionnaireId: +id,
		});

		const mappedData = createAdaptedQuestionnaire(getCaseByID);
		return mappedData;
	}

	async getQuestionnairesBySubjectId(
		subjectId: number
	): Promise<{ data: Questionnaire[]; permissions: Permissions }> {
		const {
			getQuestionnairesBySubject: { data, globalPermissions },
		} = await graphQLClient.GetQuestionnairesBySubject({
			caseType: 'questionnaire',
			subjectId,
		});

		console.log(data);

		const mappedData: Questionnaire[] =
			data?.map((questionnaire) => ({
				...questionnaire,
				description: questionnaire.description ?? '',
				topic: questionnaire.topic ?? '',
				time: questionnaire.time ?? undefined,
				subjectId: subjectId.toString(),
				caseStatus: {
					id: questionnaire.caseStatus.id,
					status: questionnaire.caseStatus.status as CaseStatusType['status'],
				},
				results: {
					averageScore: questionnaire.results?.averageScore ?? null,
					responseRate: questionnaire.results?.responseRate
						? questionnaire.results?.responseRate * 100
						: 0,
					studentAmount: questionnaire.results?.responseAmmount ?? 0,
				},
			})) ?? [];

		return {
			data: mappedData,
			permissions: (globalPermissions ?? []) as Permissions,
		};
	}

	async postQuestionnaire(data: QuestionnaireFields): Promise<string> {
		const mappedData = createGraphQLAdaptedQuestionnaire(data);

		const { postCase } = await graphQLClient.PostCase({
			data: mappedData,
			caseType: caseTypes.Questionnaire,
		});

		return postCase.id;
	}

	async postQuestionnaireDraft(data: QuestionnaireFields): Promise<string> {
		const mappedData: CreateCaseDto = {
			...createGraphQLAdaptedQuestionnaire(data),
			caseStatus: caseStatus.Draft,
		};
		const { postCase } = await graphQLClient.PostCase({
			data: mappedData,
			caseType: caseTypes.Questionnaire,
		});

		return postCase.id;
	}
}

export default new QuestionnairesApiGraphQL();
