import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	DateTime: any;
	JSON: any;
	JSONObject: any;
};

export type AcademicObject = {
	__typename?: 'AcademicObject';
	academic_product_answers: Array<AcademicProductAnswer>;
	created_at: Scalars['String'];
	description: Scalars['String'];
	id: Scalars['ID'];
	prompt: Scalars['String'];
	subjects: Array<SubjectType>;
	title: Scalars['String'];
	updated_at: Scalars['String'];
};

export type AcademicProductAnswer = {
	__typename?: 'AcademicProductAnswer';
	academic_object?: Maybe<AcademicObject>;
	academic_product_answer_review: Array<AcademicProductAnswerReview>;
	bibliography: Scalars['String'];
	client: Scalars['Float'];
	content: Scalars['String'];
	id: Scalars['ID'];
	module: Scalars['String'];
	subject: SubjectType;
	teacher: TeacherType;
	topic: Scalars['String'];
};

export type AcademicProductAnswerReview = {
	__typename?: 'AcademicProductAnswerReview';
	id: Scalars['ID'];
	question: Scalars['String'];
	type_name: Scalars['String'];
	value: Scalars['String'];
};

export type AiChatExpense = {
	__typename?: 'AiChatExpense';
	amount: Scalars['Int'];
	id: Scalars['ID'];
	teacher: TeacherType;
};

export type AiChatSubscription = {
	__typename?: 'AiChatSubscription';
	ai_chat_subscription_teacher: Array<AiChatSubscriptionTeacher>;
	id: Scalars['ID'];
	name: Scalars['String'];
	value: Scalars['Int'];
};

export type AiChatSubscriptionTeacher = {
	__typename?: 'AiChatSubscriptionTeacher';
	ai_chat_subscription: AiChatSubscription;
	end_date?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	teacher: TeacherType;
};

export type AiChatSubscriptionType = {
	__typename?: 'AiChatSubscriptionType';
	remainingEnergy: Scalars['Float'];
	subscriptionName?: Maybe<Scalars['String']>;
};

export type Bibliography = {
	__typename?: 'Bibliography';
	chapter: Array<Chapter>;
	id: Scalars['ID'];
	name: Scalars['String'];
	teacher: TeacherType;
};

export type BibliographyDataDto = {
	chapters: Array<Scalars['String']>;
	title: Scalars['String'];
};

export type CaseRankingType = {
	__typename?: 'CaseRankingType';
	rank_score: Scalars['Float'];
	score: Scalars['Float'];
	/** Nombre completo del estudiante */
	student: Scalars['String'];
	time_spent: Scalars['Float'];
};

export type CaseResultMetrics = {
	__typename?: 'CaseResultMetrics';
	average_score?: Maybe<Scalars['Float']>;
	response_amount?: Maybe<Scalars['Float']>;
	response_rate?: Maybe<Scalars['Float']>;
};

export type CaseResultType = {
	__typename?: 'CaseResultType';
	case?: Maybe<CaseType>;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	score: Scalars['Float'];
	student: StudentType;
	subject: SubjectType;
	time_spent: Scalars['Float'];
	updated_at: Scalars['String'];
};

export type CaseReviewType = {
	__typename?: 'CaseReviewType';
	case: CaseType;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	student: StudentType;
	updated_at: Scalars['String'];
	value: Scalars['Float'];
};

export type CaseStatusType = {
	__typename?: 'CaseStatusType';
	cases: Array<CaseType>;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	status: Scalars['String'];
	updated_at: Scalars['String'];
};

export type CaseType = {
	__typename?: 'CaseType';
	caseResults: Array<CaseResultType>;
	caseReviews: Array<CaseReviewType>;
	caseStatus: CaseStatusType;
	caseType: CaseType;
	children: Array<CaseType>;
	client: ClientType;
	create_from: CreateFrom;
	created_at: Scalars['String'];
	custom_cases?: Maybe<Array<CustomCaseType>>;
	date_from: Scalars['String'];
	date_to: Scalars['String'];
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	name: Scalars['String'];
	order?: Maybe<Scalars['Float']>;
	parent: CaseType;
	permissions?: Maybe<Array<Scalars['String']>>;
	questionnaire_setting?: Maybe<QuestionnaireSettingType>;
	results?: Maybe<CaseResultMetrics>;
	state?: Maybe<QuestionnaireNotificationType>;
	steps?: Maybe<Array<StepType>>;
	subject: SubjectType;
	time?: Maybe<Scalars['Float']>;
	topic?: Maybe<Scalars['String']>;
	updated_at: Scalars['String'];
};

export type CaseTypeWithPermissions = {
	__typename?: 'CaseTypeWithPermissions';
	data?: Maybe<Array<CaseType>>;
	globalPermissions?: Maybe<Array<Scalars['String']>>;
};

export type Chapter = {
	__typename?: 'Chapter';
	bibliography: Bibliography;
	id: Scalars['ID'];
	name: Scalars['String'];
};

export type ClientType = {
	__typename?: 'ClientType';
	cases: Array<CaseType>;
	configs: Array<ConfigType>;
	createAt: Scalars['String'];
	feedback: Array<Feedback>;
	id: Scalars['ID'];
	logo_image: Scalars['String'];
	name: Scalars['String'];
	settings: Scalars['JSON'];
	subjects: Array<SubjectType>;
	taxonomy_groups: Array<TaxonomyGroupType>;
	updateAt: Scalars['String'];
	users: Array<UserType>;
};

export type ConfigGroupType = {
	__typename?: 'ConfigGroupType';
	configs: Array<ConfigType>;
	createAt: Scalars['String'];
	description: Scalars['String'];
	id: Scalars['ID'];
	title: Scalars['String'];
	updateAt: Scalars['String'];
};

export type ConfigType = {
	__typename?: 'ConfigType';
	client: ClientType;
	configGroup: ConfigGroupType;
	createAt: Scalars['String'];
	description: Scalars['String'];
	id: Scalars['ID'];
	parameters: Scalars['String'];
	title: Scalars['String'];
	updateAt: Scalars['String'];
	value: Scalars['String'];
};

export type CreateAcademicObjectDto = {
	description: Scalars['String'];
	id: Scalars['ID'];
	prompt: Scalars['String'];
	title: Scalars['String'];
};

export type CreateAcademicProductAnswerDto = {
	academic_product?: InputMaybe<Scalars['Float']>;
	academic_product_answer_review: Array<CreateAcademicProductAnswerReviewDto>;
	bibliography: Scalars['String'];
	client: Scalars['Float'];
	content: Scalars['String'];
	module: Scalars['String'];
	subject: Scalars['Float'];
	topic?: InputMaybe<Scalars['String']>;
};

export type CreateAcademicProductAnswerReviewDto = {
	question: Scalars['String'];
	type_name: Scalars['String'];
	value: Scalars['String'];
};

export type CreateAiChatSubscriptionInput = {
	/** Example field (placeholder) */
	exampleField: Scalars['Int'];
};

export type CreateBatchAiQuestionnairesOptions = {
	questionsAmount?: InputMaybe<Scalars['Float']>;
	subjectCode: Scalars['String'];
};

export type CreateBatchAiQuestionnairesResponse = {
	__typename?: 'CreateBatchAIQuestionnairesResponse';
	cases: Scalars['JSON'];
	errors: Scalars['JSON'];
};

export type CreateBibliographyDto = {
	name: Scalars['String'];
};

export type CreateCaseDto = {
	caseStatus: Scalars['Float'];
	caseType: Scalars['Float'];
	client: Scalars['Float'];
	create_from?: InputMaybe<CreateFrom>;
	date_from: Scalars['String'];
	date_to: Scalars['String'];
	description?: InputMaybe<Scalars['String']>;
	name: Scalars['String'];
	order?: InputMaybe<Scalars['Float']>;
	parent?: InputMaybe<Scalars['Float']>;
	questionnaireSettings?: InputMaybe<CreateQuestionnaireSettingDto>;
	specific_learning_goal?: InputMaybe<Scalars['String']>;
	steps: Array<CreateStepDto>;
	subject: Scalars['Float'];
	time?: InputMaybe<Scalars['Float']>;
	topic?: InputMaybe<Scalars['String']>;
};

export type CreateCaseReviewDto = {
	case: Scalars['Float'];
	student: Scalars['Float'];
	value: Scalars['Float'];
};

export type CreateChapterDto = {
	name: Scalars['String'];
};

export type CreateClientDto = {
	id: Scalars['Float'];
	logo_image: Scalars['String'];
	name: Scalars['String'];
};

export type CreateConfigDto = {
	client: Scalars['Int'];
	configGroup: Scalars['Int'];
	description: Scalars['String'];
	parameters: Scalars['String'];
	title: Scalars['String'];
	value: Scalars['String'];
};

export type CreateConfigGroupDto = {
	description: Scalars['String'];
	title: Scalars['String'];
};

export type CreateFeedbackClientInput = {
	client: Scalars['Int'];
	feedback: Scalars['Int'];
};

export type CreateFeedbackDto = {
	client: Scalars['Int'];
	date_from: Scalars['String'];
	date_to: Scalars['String'];
	feedbackType: Scalars['Int'];
	options: Array<CreateFeedbackOptionsDto>;
	title: Scalars['String'];
};

export type CreateFeedbackOptionsDto = {
	icon_name: Scalars['String'];
	title: Scalars['String'];
};

export type CreateFeedbackResultsInput = {
	feedbackOption: Scalars['Int'];
	user: Scalars['Int'];
};

export type CreateFeedbackSubjectInput = {
	feedback: Scalars['Int'];
	subject: Scalars['Int'];
};

export type CreateFeedbackTypeInput = {
	title: Scalars['String'];
};

export type CreateFileInput = {
	base64?: InputMaybe<Scalars['String']>;
	contentUrl?: InputMaybe<Scalars['String']>;
};

export enum CreateFrom {
	Ai = 'AI',
	Human = 'HUMAN',
}

export type CreateInquiryDto = {
	client: Scalars['Int'];
	message: Scalars['String'];
	student: Scalars['Int'];
};

export type CreateJustificationDto = {
	description: Scalars['String'];
	files?: InputMaybe<Array<CreateFileInput>>;
	step?: InputMaybe<Scalars['Float']>;
};

export type CreateQuestionnaireResultDto = {
	case: Scalars['Float'];
	score?: InputMaybe<Scalars['Float']>;
	steps?: InputMaybe<Array<CreateStepResultDto>>;
	student: Scalars['Float'];
	time_spent?: InputMaybe<Scalars['Float']>;
};

export type CreateQuestionnaireSettingDto = {
	aleatory_options: Scalars['Boolean'];
	case?: InputMaybe<Scalars['Int']>;
	is_math: Scalars['Boolean'];
	show_correct_option: Scalars['Boolean'];
};

export type CreateStepDto = {
	cases?: InputMaybe<Array<Scalars['Int']>>;
	custom_time?: InputMaybe<Scalars['Float']>;
	files?: InputMaybe<Array<CreateFileInput>>;
	id?: InputMaybe<Scalars['Float']>;
	is_math?: InputMaybe<Scalars['Boolean']>;
	justification?: InputMaybe<CreateJustificationDto>;
	open_field?: InputMaybe<Scalars['Boolean']>;
	order?: InputMaybe<Scalars['Float']>;
	stepType: Scalars['Float'];
	step_options: Array<CreateStepOptionsDto>;
	title: Scalars['String'];
};

export type CreateStepOptionsDto = {
	correct?: InputMaybe<Scalars['Boolean']>;
	files?: InputMaybe<Array<CreateFileInput>>;
	is_math?: InputMaybe<Scalars['Boolean']>;
	step?: InputMaybe<Scalars['Float']>;
	title: Scalars['String'];
};

export type CreateStepResultDto = {
	answer_result_text?: InputMaybe<Scalars['String']>;
	case?: InputMaybe<Scalars['Float']>;
	caseResult?: InputMaybe<Scalars['Float']>;
	correct?: InputMaybe<Scalars['Boolean']>;
	id?: InputMaybe<Scalars['Float']>;
	out_of_time: Scalars['Boolean'];
	step: Scalars['Float'];
	stepOptionId: Array<Scalars['Float']>;
	student?: InputMaybe<Scalars['Float']>;
	time_spent: Scalars['Float'];
};

export type CreateStudentDto = {
	authType: Scalars['String'];
	client: Scalars['Int'];
	email: Scalars['String'];
	first_name: Scalars['String'];
	is_active?: Scalars['Boolean'];
	is_confirmed?: Scalars['Boolean'];
	is_first_use?: Scalars['Boolean'];
	last_name: Scalars['String'];
	national_identification?: InputMaybe<Scalars['String']>;
	password: Scalars['String'];
	phone_number?: InputMaybe<Scalars['String']>;
	profile_picture?: InputMaybe<CreateFileInput>;
	taxonomies?: InputMaybe<Array<CreateTaxonomyUserDto>>;
};

export type CreateSubjectDto = {
	client: Scalars['Int'];
	code: Scalars['String'];
	date_from: Scalars['DateTime'];
	date_to: Scalars['DateTime'];
	description: Scalars['String'];
	name: Scalars['String'];
	student_ammount: Scalars['Int'];
	subject_status: Scalars['Int'];
	taxonomies?: InputMaybe<CreateTaxonomySubjectDto>;
};

export type CreateSyllabusStructureDto = {
	bibliography: Array<Scalars['String']>;
	complementary_bibliography: Array<Scalars['String']>;
	learning_goals: Array<Scalars['String']>;
	name: Scalars['String'];
	order?: InputMaybe<Scalars['Float']>;
	subject_code?: InputMaybe<Scalars['String']>;
	syllabus_structure_children?: InputMaybe<Array<CreateSyllabusStructureDto>>;
	type: Scalars['String'];
};

export type CreateTaxonomyDto = {
	children?: InputMaybe<Array<Scalars['Int']>>;
	description: Scalars['String'];
	group?: InputMaybe<Scalars['Int']>;
	name: Scalars['String'];
	parents?: InputMaybe<Array<CreateTaxonomyTaxonomyDto>>;
};

export type CreateTaxonomyEntityDto = {
	alias: Scalars['String'];
	entity_name: Scalars['String'];
	taxonomy_groups?: InputMaybe<Array<Scalars['Int']>>;
};

export type CreateTaxonomyGroupDto = {
	children?: InputMaybe<Array<Scalars['Int']>>;
	client: Scalars['Float'];
	description: Scalars['String'];
	name: Scalars['String'];
	parent?: InputMaybe<Scalars['Int']>;
	taxonomies?: InputMaybe<Array<Scalars['Int']>>;
	taxonomy_entities?: InputMaybe<Array<Scalars['Int']>>;
};

export type CreateTaxonomySubjectDto = {
	parentRelationId?: InputMaybe<Scalars['Int']>;
	subjectId: Scalars['Int'];
	taxonomyId: Scalars['Int'];
};

export type CreateTaxonomyTaxonomyDto = {
	id?: InputMaybe<Scalars['ID']>;
	parent: Scalars['Float'];
	parentRelationId?: InputMaybe<Scalars['Int']>;
};

export type CreateTaxonomyUserDto = {
	parentRelationId?: InputMaybe<Scalars['Int']>;
	taxonomyId?: InputMaybe<Scalars['Int']>;
	userId?: InputMaybe<Scalars['Int']>;
};

export type CreateTeacherDto = {
	authType: Scalars['String'];
	client: Scalars['Int'];
	email: Scalars['String'];
	first_name: Scalars['String'];
	is_active?: Scalars['Boolean'];
	is_confirmed?: Scalars['Boolean'];
	is_first_use?: Scalars['Boolean'];
	last_name: Scalars['String'];
	national_identification?: InputMaybe<Scalars['String']>;
	password: Scalars['String'];
	phone_number?: InputMaybe<Scalars['String']>;
	profile_picture?: InputMaybe<CreateFileInput>;
	taxonomies?: InputMaybe<Array<CreateTaxonomyUserDto>>;
};

export type CreateUserDto = {
	client: Scalars['Int'];
	email: Scalars['String'];
	first_name: Scalars['String'];
	is_active?: Scalars['Boolean'];
	is_confirmed?: Scalars['Boolean'];
	is_first_use?: Scalars['Boolean'];
	last_name: Scalars['String'];
	national_identification?: InputMaybe<Scalars['String']>;
	password: Scalars['String'];
	phone_number?: InputMaybe<Scalars['String']>;
	profile_picture?: InputMaybe<CreateFileInput>;
	taxonomies?: InputMaybe<Array<CreateTaxonomyUserDto>>;
};

export type CustomCaseType = {
	__typename?: 'CustomCaseType';
	cases: CaseType;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	student: StudentType;
	updated_at: Scalars['String'];
};

export type Feedback = {
	__typename?: 'Feedback';
	date_from: Scalars['String'];
	date_to: Scalars['String'];
	feedbackClient: FeedbackClient;
	feedbackOptions: Array<FeedbackOptions>;
	feedbackSubject: FeedbackSubject;
	feedbackType: FeedbackType;
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type FeedbackClient = {
	__typename?: 'FeedbackClient';
	client: ClientType;
	feedback: Feedback;
};

export type FeedbackOptions = {
	__typename?: 'FeedbackOptions';
	feedback: Feedback;
	feedbackResults: Array<FeedbackResults>;
	icon_name: Scalars['String'];
	id: Scalars['ID'];
	title: Scalars['String'];
};

export type FeedbackResults = {
	__typename?: 'FeedbackResults';
	created_at: Scalars['DateTime'];
	feedbackOptions: FeedbackOptions;
	user: UserType;
};

export type FeedbackSubject = {
	__typename?: 'FeedbackSubject';
	feedback: Feedback;
	subject: SubjectType;
};

export type FeedbackType = {
	__typename?: 'FeedbackType';
	feedback: Scalars['Int'];
	title: Scalars['String'];
};

export type File = {
	__typename?: 'File';
	bucket_name: Scalars['String'];
	mime_type: Scalars['String'];
	name: Scalars['String'];
	path: Scalars['String'];
	syllabus_files?: Maybe<Array<SyllabusFiles>>;
};

export type FileType = {
	__typename?: 'FileType';
	bucket_name: Scalars['String'];
	created_at: Scalars['DateTime'];
	id: Scalars['Float'];
	mime_type: Scalars['String'];
	name: Scalars['String'];
	path: Scalars['String'];
	updated_at: Scalars['DateTime'];
};

export type Inquiry = {
	__typename?: 'Inquiry';
	client: ClientType;
	id: Scalars['ID'];
	message: Scalars['String'];
	student: StudentType;
};

export type JustificationType = {
	__typename?: 'JustificationType';
	created_at: Scalars['String'];
	description: Scalars['String'];
	files: Array<FileType>;
	id: Scalars['ID'];
	step: StepType;
	updated_at: Scalars['String'];
};

export type LoginType = {
	__typename?: 'LoginType';
	client: ClientType;
	created_at: Scalars['String'];
	email: Scalars['String'];
	first_name: Scalars['String'];
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	is_confirmed: Scalars['Boolean'];
	is_first_use: Scalars['Boolean'];
	last_login?: Maybe<Scalars['DateTime']>;
	last_name: Scalars['String'];
	national_identification?: Maybe<Scalars['String']>;
	password: Scalars['String'];
	phone_number?: Maybe<Scalars['String']>;
	routers: Array<Scalars['String']>;
	student: StudentType;
	taxonomies: Array<UserTaxonomy>;
	teacher: TeacherType;
	token: Scalars['String'];
	updated_at: Scalars['String'];
	uuids: Array<UuidType>;
};

export type MobileCaseType = {
	__typename?: 'MobileCaseType';
	caseResults: Array<CaseResultType>;
	caseReviews: Array<CaseReviewType>;
	caseState?: Maybe<Scalars['String']>;
	caseStatus: CaseStatusType;
	caseType: CaseType;
	children: Array<CaseType>;
	client: ClientType;
	created_at: Scalars['String'];
	date_from: Scalars['String'];
	date_to: Scalars['String'];
	description?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	is_answered?: Maybe<Scalars['Boolean']>;
	name: Scalars['String'];
	order?: Maybe<Scalars['Float']>;
	parent: CaseType;
	questionnaire_setting?: Maybe<QuestionnaireSettingType>;
	steps?: Maybe<Array<StepType>>;
	subject: SubjectType;
	time?: Maybe<Scalars['Float']>;
	topic?: Maybe<Scalars['String']>;
	updated_at: Scalars['String'];
};

export type Mutation = {
	__typename?: 'Mutation';
	Conti_duplicated_NRC_STEP: Scalars['String'];
	POST_Feedback: Feedback;
	POST_FeedbackClient: FeedbackClient;
	POST_FeedbackResults: FeedbackResults;
	POST_FeedbackSubject: FeedbackSubject;
	POST_FeedbackType: FeedbackType;
	addExpense: AiChatExpense;
	addSubscription: Subscription;
	addSyllabusFile: SyllabusFiles;
	appleLogin: LoginType;
	archiveStudentSubject: Scalars['String'];
	confirmEmail: UserType;
	createAcademicObject: AcademicObject;
	createAcademicObjectsBatch: Array<AcademicObject>;
	createAiChatSubscription: AiChatSubscription;
	createBatchAIQuestionnaires: CreateBatchAiQuestionnairesResponse;
	createCasesFromSheet: Scalars['Boolean'];
	createSubjectTaxonomyRelation: SubjectTaxonomyType;
	createSyllabus: SyllabusStructure;
	createSyllabusBatchFromSheet: Response;
	deleteAcademicProductAnswer: Scalars['Boolean'];
	deleteBibliographyById: Scalars['String'];
	deleteCaseResultBySubject: Scalars['String'];
	deleteCases: Scalars['String'];
	deleteClient: Scalars['String'];
	deleteConfig: Scalars['String'];
	deleteSubjectById: Scalars['String'];
	deleteTaxonomy: Scalars['String'];
	deleteTaxonomyEntity: Array<TaxonomyEntityType>;
	deleteTaxonomyGroups: Array<TaxonomyGroupType>;
	deleteUsers: Scalars['String'];
	disableUser: Scalars['Boolean'];
	duplicateCase: CaseType;
	duplicateCaseToSubject: CaseType;
	duplicateSubjectWithContents: SubjectType;
	facebookLogin: LoginType;
	forgotPassword: Scalars['String'];
	googleLogin: LoginType;
	linkAcademicObjectsToSubjects: Scalars['JSON'];
	loginMobile: LoginType;
	loginweb: LoginType;
	postAcademicProductAnswer: AcademicProductAnswer;
	postBibliography: Bibliography;
	postBibliographyWithChapters: Bibliography;
	postCase: CaseType;
	postCaseReview: CaseReviewType;
	postChapter: Chapter;
	postClients: ClientType;
	postConfig: ConfigType;
	postConfigGroup: ConfigGroupType;
	postInquiry: Inquiry;
	postQuestionnaireResults: PostCaseResultResponse;
	postStepResult: StepResultType;
	postSubject: SubjectType;
	postSubjectTaxonomy: SubjectTaxonomyType;
	postTaxonomy: TaxonomyType;
	postTaxonomyEntity: TaxonomyEntityType;
	postTaxonomyGroups: TaxonomyGroupType;
	postUserTaxonomy: UserTaxonomyType;
	postUsers: UserType;
	putAddRelationTaxonomyEntityGroup: TaxonomyEntityType;
	putCase: CaseType;
	putCaseOrder: Scalars['String'];
	putConfig: ConfigType;
	putConfigGroup: ConfigGroupType;
	putRemoveRelationTaxonomyEntityGroup: TaxonomyEntityType;
	putStudent: StudentType;
	putTaxonomyEntity: TaxonomyEntityType;
	putTaxonomyGroups: TaxonomyGroupType;
	putTeacher: TeacherType;
	redeemSubjectByCode: SubjectType;
	refreshTeacherToken: LoginType;
	refreshTokenMobile: Scalars['String'];
	register: TeacherType;
	removeAcademicObject: AcademicObject;
	removeAiChatSubscription: AiChatSubscription;
	resetPassword: Scalars['String'];
	studentRegister: StudentType;
	studentResetPassword: Scalars['String'];
	teacherGoogleLogin: LoginType;
	updateAcademicObject: AcademicObject;
	updateAcademicProductAnswer: AcademicProductAnswer;
	updateAiChatSubscription: AiChatSubscription;
	updateClient: ClientType;
	updateFile: File;
	updateSubject: SubjectType;
	updateUsers: UserType;
	uploadFile: UploadObjectResponse;
	verifyTokenExpiration: Scalars['Boolean'];
};

export type MutationConti_Duplicated_Nrc_StepArgs = {
	subjectCode: Scalars['String'];
};

export type MutationPost_FeedbackArgs = {
	payload: CreateFeedbackDto;
};

export type MutationPost_FeedbackClientArgs = {
	payload: CreateFeedbackClientInput;
};

export type MutationPost_FeedbackResultsArgs = {
	payload: CreateFeedbackResultsInput;
};

export type MutationPost_FeedbackSubjectArgs = {
	payload: CreateFeedbackSubjectInput;
};

export type MutationPost_FeedbackTypeArgs = {
	payload: CreateFeedbackTypeInput;
};

export type MutationAddExpenseArgs = {
	value: Scalars['Float'];
};

export type MutationAddSubscriptionArgs = {
	planId: Scalars['Int'];
	teacherId: Scalars['Int'];
};

export type MutationAddSyllabusFileArgs = {
	file: CreateFileInput;
	fileName?: InputMaybe<Scalars['String']>;
	syllabusId: Scalars['Int'];
};

export type MutationAppleLoginArgs = {
	client: Scalars['Float'];
	identityToken: Scalars['String'];
	userData: UpdateUserDto;
};

export type MutationArchiveStudentSubjectArgs = {
	id: Scalars['Float'];
};

export type MutationConfirmEmailArgs = {
	token: Scalars['String'];
};

export type MutationCreateAcademicObjectArgs = {
	createAcademicObjectInput: CreateAcademicObjectDto;
};

export type MutationCreateAiChatSubscriptionArgs = {
	createAiChatSubscriptionInput: CreateAiChatSubscriptionInput;
};

export type MutationCreateBatchAiQuestionnairesArgs = {
	data: CreateBatchAiQuestionnairesOptions;
};

export type MutationCreateCasesFromSheetArgs = {
	sheetPage: Scalars['String'];
	subjectCode: Scalars['String'];
	subjectName: Scalars['String'];
};

export type MutationCreateSubjectTaxonomyRelationArgs = {
	subjectId: Scalars['Int'];
	taxonomies: Scalars['Int'];
};

export type MutationCreateSyllabusArgs = {
	data: CreateSyllabusStructureDto;
};

export type MutationCreateSyllabusBatchFromSheetArgs = {
	page: Scalars['String'];
	sheetId: Scalars['String'];
};

export type MutationDeleteAcademicProductAnswerArgs = {
	academicProductAnswerId: Scalars['Float'];
};

export type MutationDeleteBibliographyByIdArgs = {
	id: Scalars['Float'];
};

export type MutationDeleteCaseResultBySubjectArgs = {
	duplicatePermissions?: InputMaybe<Scalars['Boolean']>;
	subjectId: Scalars['Float'];
};

export type MutationDeleteCasesArgs = {
	caseType: Scalars['String'];
	ids: Array<Scalars['Float']>;
};

export type MutationDeleteClientArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteConfigArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteSubjectByIdArgs = {
	id: Scalars['Float'];
};

export type MutationDeleteTaxonomyArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteTaxonomyEntityArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteTaxonomyGroupsArgs = {
	id: Scalars['Int'];
};

export type MutationDeleteUsersArgs = {
	id: Scalars['Int'];
};

export type MutationDuplicateCaseArgs = {
	caseId: Scalars['Float'];
};

export type MutationDuplicateCaseToSubjectArgs = {
	caseId: Scalars['Float'];
	subjectId: Scalars['Float'];
};

export type MutationDuplicateSubjectWithContentsArgs = {
	data?: InputMaybe<UpdateSubjectDto>;
	subjectId: Scalars['Float'];
};

export type MutationFacebookLoginArgs = {
	accessToken: Scalars['String'];
	client: Scalars['Float'];
};

export type MutationForgotPasswordArgs = {
	user: UpdateUserDto;
};

export type MutationGoogleLoginArgs = {
	client: Scalars['Float'];
	idToken: Scalars['String'];
};

export type MutationLinkAcademicObjectsToSubjectsArgs = {
	page: Scalars['String'];
	sheetId: Scalars['String'];
};

export type MutationLoginMobileArgs = {
	student: UpdateUserDto;
};

export type MutationLoginwebArgs = {
	teacher: UpdateUserDto;
};

export type MutationPostAcademicProductAnswerArgs = {
	data: CreateAcademicProductAnswerDto;
};

export type MutationPostBibliographyArgs = {
	payload: CreateBibliographyDto;
};

export type MutationPostBibliographyWithChaptersArgs = {
	payload: BibliographyDataDto;
};

export type MutationPostCaseArgs = {
	caseType: Scalars['String'];
	data: CreateCaseDto;
};

export type MutationPostCaseReviewArgs = {
	data: CreateCaseReviewDto;
};

export type MutationPostChapterArgs = {
	payload: CreateChapterDto;
};

export type MutationPostClientsArgs = {
	data: CreateClientDto;
};

export type MutationPostConfigArgs = {
	data: CreateConfigDto;
};

export type MutationPostConfigGroupArgs = {
	data: CreateConfigGroupDto;
};

export type MutationPostInquiryArgs = {
	payload: CreateInquiryDto;
};

export type MutationPostQuestionnaireResultsArgs = {
	data: CreateQuestionnaireResultDto;
};

export type MutationPostStepResultArgs = {
	data: CreateStepResultDto;
};

export type MutationPostSubjectArgs = {
	data: CreateSubjectDto;
};

export type MutationPostSubjectTaxonomyArgs = {
	data: CreateTaxonomySubjectDto;
};

export type MutationPostTaxonomyArgs = {
	data?: InputMaybe<CreateTaxonomyDto>;
};

export type MutationPostTaxonomyEntityArgs = {
	data: CreateTaxonomyEntityDto;
};

export type MutationPostTaxonomyGroupsArgs = {
	data: CreateTaxonomyGroupDto;
};

export type MutationPostUserTaxonomyArgs = {
	data: CreateTaxonomyUserDto;
};

export type MutationPostUsersArgs = {
	data: CreateUserDto;
};

export type MutationPutAddRelationTaxonomyEntityGroupArgs = {
	entityId: Scalars['Int'];
	groupId: Scalars['Int'];
};

export type MutationPutCaseArgs = {
	data: UpdateCaseDto;
	id: Scalars['Float'];
};

export type MutationPutCaseOrderArgs = {
	caseId: Scalars['Float'];
	order: Scalars['Float'];
};

export type MutationPutConfigArgs = {
	changes: UpdateConfigDto;
	id: Scalars['Float'];
};

export type MutationPutConfigGroupArgs = {
	data: UpdateConfigGroupDto;
	id: Scalars['Int'];
};

export type MutationPutRemoveRelationTaxonomyEntityGroupArgs = {
	entityId: Scalars['Int'];
	groupId: Scalars['Int'];
};

export type MutationPutStudentArgs = {
	changes: UpdateStudentDto;
	id: Scalars['Int'];
};

export type MutationPutTaxonomyEntityArgs = {
	data: UpdateTaxonomyEntityDto;
	id: Scalars['Int'];
};

export type MutationPutTaxonomyGroupsArgs = {
	data: CreateTaxonomyGroupDto;
	id: Scalars['Int'];
};

export type MutationPutTeacherArgs = {
	changes: UpdateTeacherDto;
	id: Scalars['Int'];
};

export type MutationRedeemSubjectByCodeArgs = {
	subjectCode: Scalars['String'];
};

export type MutationRefreshTeacherTokenArgs = {
	refreshToken: Scalars['String'];
};

export type MutationRefreshTokenMobileArgs = {
	refreshToken: Scalars['String'];
};

export type MutationRegisterArgs = {
	data: CreateTeacherDto;
	userType: Scalars['String'];
};

export type MutationRemoveAcademicObjectArgs = {
	id: Scalars['Int'];
};

export type MutationRemoveAiChatSubscriptionArgs = {
	id: Scalars['Int'];
};

export type MutationResetPasswordArgs = {
	password: Scalars['String'];
	token: Scalars['String'];
};

export type MutationStudentRegisterArgs = {
	data: CreateStudentDto;
};

export type MutationStudentResetPasswordArgs = {
	user: UpdateUserDto;
};

export type MutationTeacherGoogleLoginArgs = {
	client: Scalars['Float'];
	code: Scalars['String'];
};

export type MutationUpdateAcademicObjectArgs = {
	updateAcademicObjectInput: UpdateAcademicObjectDto;
};

export type MutationUpdateAcademicProductAnswerArgs = {
	data: UpdateAcademicProductAnswerDto;
};

export type MutationUpdateAiChatSubscriptionArgs = {
	updateAiChatSubscriptionInput: UpdateAiChatSubscriptionInput;
};

export type MutationUpdateClientArgs = {
	data: UpdateClientDto;
	id: Scalars['Int'];
};

export type MutationUpdateFileArgs = {
	updateFileInput: UpdateFileInput;
};

export type MutationUpdateSubjectArgs = {
	data: UpdateSubjectDto;
	id: Scalars['Float'];
};

export type MutationUpdateUsersArgs = {
	data: UpdateUserDto;
	id: Scalars['Int'];
};

export type MutationUploadFileArgs = {
	base64String: Scalars['String'];
};

export type MutationVerifyTokenExpirationArgs = {
	token: Scalars['String'];
};

export type Plan = {
	__typename?: 'Plan';
	id: Scalars['ID'];
	name: Scalars['String'];
	subscriptions: Array<Subscription>;
};

export type PostCaseResultResponse = {
	__typename?: 'PostCaseResultResponse';
	score: Scalars['Int'];
};

export type Query = {
	__typename?: 'Query';
	GET_Feedback: Array<Feedback>;
	GET_FeedbackClient: Array<FeedbackClient>;
	GET_FeedbackOptions: FeedbackOptions;
	GET_FeedbackSubject: Array<FeedbackSubject>;
	GetClientById: ClientType;
	academicObject: AcademicObject;
	academicObjects: Array<AcademicObject>;
	aiChatSubscription: AiChatSubscription;
	aiChatSubscriptions: Array<AiChatSubscription>;
	checkEmail: Scalars['Boolean'];
	checkSubjectCode: Scalars['Boolean'];
	evaluadosAppReportHeaders: Scalars['JSONObject'];
	findInterruptedQuestionnaire?: Maybe<CaseType>;
	getAcademicObjectsForSubject: Array<AcademicObject>;
	getAcademicProductAnswersForTeacher: Array<AcademicProductAnswer>;
	getAllSubjects: Array<SubjectType>;
	getBibliography: Array<Bibliography>;
	getByIds: Array<TaxonomyType>;
	getByParent: Array<TaxonomyType>;
	getCaseByID: CaseType;
	getCaseRanking: Array<CaseRankingType>;
	getCases: Array<CaseType>;
	getCasesBySubjectId: Array<MobileCaseType>;
	getCasesBySubjectToDashboard: Array<CaseType>;
	getChapters: Array<Chapter>;
	getClients: Array<ClientType>;
	getConfigById: ConfigType;
	getConfigGroupById: ConfigGroupType;
	getConfigGroups: Array<ConfigGroupType>;
	getConfigs: Array<ConfigType>;
	getConfigsByClient: Array<ConfigType>;
	getEntityTaxonomiesByEntityName: TaxonomyEntityType;
	getError: Scalars['String'];
	getEvaluadosAppReportCards: Scalars['JSONObject'];
	getHomeQuestionnaires: Array<CaseType>;
	getInquiries: Array<Inquiry>;
	getLowPerformanceQuestionnaires: Array<CaseType>;
	getManyTaxonomyGroups: Array<TaxonomyGroupType>;
	getQuestionnaireStatus?: Maybe<QuestionnaireNotificationType>;
	getQuestionnairesBySubject: CaseTypeWithPermissions;
	getQuestionsOfInterest: Array<StepResultType>;
	getRoutersForUser: Array<Scalars['String']>;
	getStudentByID: StudentType;
	getStudentSubjects: Array<SubjectType>;
	getStudents: Array<StudentType>;
	getSubjectAcademicObjectAnswers: Array<AcademicProductAnswer>;
	getSubjectActiveWithTaxonomy: Array<SubjectType>;
	getSubjectByClientWithPagination: Array<SubjectType>;
	getSubjectByID: SubjectType;
	getSubjectByTeacher: Array<SubjectType>;
	getSubjectStudents: SubjectType;
	getSubjectSyllabus: SyllabusStructure;
	getSubjects: SubjectTypeWithPermissions;
	getSurveysBySubject: CaseTypeWithPermissions;
	getTaxonomies: Array<TaxonomyType>;
	getTaxonomyById: TaxonomyType;
	getTaxonomyEntity: Array<TaxonomyEntityType>;
	getTaxonomyEntityById: TaxonomyEntityType;
	getTaxonomyGroupById: TaxonomyGroupType;
	getTaxonomyGroups: Array<TaxonomyGroupType>;
	getTeacherAIEnergy: AiChatSubscriptionType;
	getTeacherBibliography: Array<Bibliography>;
	getTeacherByID: TeacherType;
	getTeacherSyllabus: Array<SyllabusStructure>;
	getTeachers: Array<TeacherType>;
	getTrajectoryResume: TrajectoryResumeType;
	getUsers: Array<UserType>;
	getUsersByID: UserType;
	reportToDashboard: Array<Scalars['JSONObject']>;
	reportToDashboardEvaluadosApp: Scalars['JSONObject'];
	testAuth: Scalars['String'];
};

export type QueryGetClientByIdArgs = {
	id: Scalars['Int'];
};

export type QueryAcademicObjectArgs = {
	id: Scalars['Int'];
};

export type QueryAiChatSubscriptionArgs = {
	id: Scalars['Int'];
};

export type QueryCheckEmailArgs = {
	email: Scalars['String'];
};

export type QueryCheckSubjectCodeArgs = {
	code: Scalars['String'];
};

export type QueryGetAcademicObjectsForSubjectArgs = {
	subjectId: Scalars['Int'];
};

export type QueryGetByIdsArgs = {
	ids: Array<Scalars['Int']>;
};

export type QueryGetByParentArgs = {
	parentId: Scalars['Int'];
};

export type QueryGetCaseByIdArgs = {
	id: Scalars['Float'];
	skipResults?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetCaseRankingArgs = {
	caseId: Scalars['Float'];
};

export type QueryGetCasesArgs = {
	caseType: Scalars['String'];
};

export type QueryGetCasesBySubjectIdArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetCasesBySubjectToDashboardArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetConfigByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetConfigGroupByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetConfigsByClientArgs = {
	id: Scalars['Int'];
};

export type QueryGetEntityTaxonomiesByEntityNameArgs = {
	clientId: Scalars['Float'];
	entity: Scalars['String'];
};

export type QueryGetErrorArgs = {
	errorCode: Scalars['String'];
};

export type QueryGetLowPerformanceQuestionnairesArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetManyTaxonomyGroupsArgs = {
	clientId: Scalars['Int'];
	taxonomyEntityId: Scalars['Int'];
};

export type QueryGetQuestionnaireStatusArgs = {
	caseId: Scalars['Float'];
};

export type QueryGetQuestionnairesBySubjectArgs = {
	caseType: Scalars['String'];
	subjectId: Scalars['Float'];
};

export type QueryGetQuestionsOfInterestArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetStudentByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetSubjectAcademicObjectAnswersArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetSubjectActiveWithTaxonomyArgs = {
	taxonomyIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryGetSubjectByClientWithPaginationArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	searchArgs?: InputMaybe<Array<SearchArgs>>;
};

export type QueryGetSubjectByIdArgs = {
	id: Scalars['Float'];
};

export type QueryGetSubjectByTeacherArgs = {
	id: Scalars['Float'];
};

export type QueryGetSubjectStudentsArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetSubjectSyllabusArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetSurveysBySubjectArgs = {
	caseType: Scalars['String'];
	subjectId: Scalars['Float'];
};

export type QueryGetTaxonomyByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetTaxonomyEntityByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetTaxonomyGroupByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetTeacherByIdArgs = {
	id: Scalars['Int'];
};

export type QueryGetTrajectoryResumeArgs = {
	subjectId: Scalars['Float'];
};

export type QueryGetUsersByIdArgs = {
	id: Scalars['Int'];
};

export type QueryReportToDashboardEvaluadosAppArgs = {
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	searchArgs?: InputMaybe<Array<SearchArgs>>;
	searchQuery?: InputMaybe<Scalars['String']>;
	year: Scalars['String'];
};

export type QuestionnaireNotificationType = {
	__typename?: 'QuestionnaireNotificationType';
	status?: Maybe<Scalars['String']>;
};

export type QuestionnaireSettingType = {
	__typename?: 'QuestionnaireSettingType';
	aleatory_options: Scalars['Boolean'];
	case: CaseType;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	is_math: Scalars['Boolean'];
	show_correct_option: Scalars['Boolean'];
	updated_at: Scalars['String'];
};

export type Response = {
	__typename?: 'Response';
	fulfilled: Scalars['JSON'];
	rejected: Scalars['JSON'];
};

export type SearchArgs = {
	entity?: InputMaybe<Scalars['String']>;
	field?: InputMaybe<Scalars['String']>;
	search?: InputMaybe<Scalars['String']>;
};

export type StepOptionResultType = {
	__typename?: 'StepOptionResultType';
	created_at?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Float']>;
	step?: Maybe<StepType>;
	step_option?: Maybe<StepOptionType>;
	step_result?: Maybe<StepResultType>;
	updated_at?: Maybe<Scalars['String']>;
};

export type StepOptionType = {
	__typename?: 'StepOptionType';
	correct?: Maybe<Scalars['Boolean']>;
	created_at: Scalars['String'];
	files?: Maybe<Array<FileType>>;
	id: Scalars['ID'];
	is_math: Scalars['Boolean'];
	step: StepType;
	title: Scalars['String'];
	updated_at: Scalars['String'];
};

export type StepResultType = {
	__typename?: 'StepResultType';
	case: CaseType;
	caseResult: CaseResultType;
	correct: Scalars['Boolean'];
	created_at: Scalars['String'];
	id: Scalars['ID'];
	out_of_time: Scalars['Boolean'];
	step: StepType;
	step_option_results: Array<StepOptionResultType>;
	student: StudentType;
	/** Solo para mobile actualmente */
	student_answer_group_percentage?: Maybe<Scalars['Float']>;
	text_answer_results: Array<TextAnswerResults>;
	time_spent: Scalars['Float'];
	updated_at: Scalars['String'];
};

export type StepType = {
	__typename?: 'StepType';
	cases?: Maybe<Array<CaseType>>;
	created_at: Scalars['String'];
	custom_time?: Maybe<Scalars['Float']>;
	files?: Maybe<Array<FileType>>;
	id: Scalars['ID'];
	is_math: Scalars['Boolean'];
	justification?: Maybe<JustificationType>;
	open_field?: Maybe<Scalars['Boolean']>;
	order: Scalars['Float'];
	step_options?: Maybe<Array<StepOptionType>>;
	step_results?: Maybe<Array<StepResultType>>;
	step_type: StepTypeType;
	title: Scalars['String'];
	updated_at: Scalars['String'];
};

export type StepTypeType = {
	__typename?: 'StepTypeType';
	id: Scalars['ID'];
	name: Scalars['String'];
	steps: Array<StepType>;
};

export type StudentSubjectType = {
	__typename?: 'StudentSubjectType';
	created_at: Scalars['String'];
	id: Scalars['ID'];
	isActive?: Maybe<Scalars['Boolean']>;
	student?: Maybe<StudentType>;
	subject?: Maybe<SubjectType>;
	updated_at: Scalars['String'];
};

export type StudentType = {
	__typename?: 'StudentType';
	authType: Scalars['String'];
	caseResults?: Maybe<Array<CaseResultType>>;
	caseReviews?: Maybe<Array<CaseReviewType>>;
	createAt: Scalars['String'];
	customCases?: Maybe<Array<CustomCaseType>>;
	id: Scalars['ID'];
	profile_picture?: Maybe<FileType>;
	subjects: Array<SubjectType>;
	token: Scalars['String'];
	updateAt: Scalars['String'];
	user: UserType;
};

export type SubjectStatusType = {
	__typename?: 'SubjectStatusType';
	created_at: Scalars['String'];
	id: Scalars['ID'];
	name: Scalars['String'];
	subjects: Array<SubjectType>;
	updated_at: Scalars['String'];
};

export type SubjectTaxonomyType = {
	__typename?: 'SubjectTaxonomyType';
	created_at: Scalars['String'];
	id: Scalars['ID'];
	parent_relation: TaxonomyTaxonomyType;
	subject: SubjectType;
	taxonomy: TaxonomyType;
	updated_at: Scalars['String'];
};

export type SubjectType = {
	__typename?: 'SubjectType';
	averageScore?: Maybe<Scalars['Float']>;
	cases: Array<CaseType>;
	client: ClientType;
	code: Scalars['String'];
	created_at: Scalars['DateTime'];
	date_from: Scalars['DateTime'];
	date_to: Scalars['DateTime'];
	description?: Maybe<Scalars['String']>;
	feedbackSubject: Array<FeedbackSubject>;
	id: Scalars['ID'];
	name: Scalars['String'];
	permissions?: Maybe<Array<Scalars['String']>>;
	studentSubjects?: Maybe<Array<StudentSubjectType>>;
	student_ammount?: Maybe<Scalars['Float']>;
	subject_status: SubjectStatusType;
	taxonomies: Array<SubjectTaxonomyType>;
	teachers?: Maybe<Array<TeacherSubjectType>>;
	updated_at: Scalars['DateTime'];
};

export type SubjectTypeWithPermissions = {
	__typename?: 'SubjectTypeWithPermissions';
	data: Array<SubjectType>;
	globalPermissions: Array<Scalars['String']>;
};

export type Subscription = {
	__typename?: 'Subscription';
	end_date?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	plan: Plan;
	teacher: TeacherType;
};

export type SyllabusFiles = {
	__typename?: 'SyllabusFiles';
	file?: Maybe<File>;
	fileId: Scalars['Float'];
	id: Scalars['Float'];
	status: Scalars['String'];
	syllabus?: Maybe<SyllabusStructure>;
	syllabusId: Scalars['Float'];
	taskId: Scalars['String'];
};

export type SyllabusStructure = {
	__typename?: 'SyllabusStructure';
	bibliography: Array<Scalars['String']>;
	complementary_bibliography: Array<Scalars['String']>;
	id: Scalars['ID'];
	learning_goals: Array<Scalars['String']>;
	name: Scalars['String'];
	order: Scalars['Float'];
	subject?: Maybe<SubjectType>;
	syllabus_files?: Maybe<Array<SyllabusFiles>>;
	syllabus_structure_children: Scalars['JSON'];
	syllabus_structure_parent: SyllabusStructure;
	type: SyllabusStructureType;
};

export enum SyllabusStructureType {
	Module = 'MODULE',
	Syllabus = 'SYLLABUS',
	Topic = 'TOPIC',
}

export type TaxonomyEntityType = {
	__typename?: 'TaxonomyEntityType';
	alias: Scalars['String'];
	created_at: Scalars['String'];
	entity_name: Scalars['String'];
	id: Scalars['ID'];
	taxonomy_groups: Array<TaxonomyGroupType>;
	updated_at: Scalars['String'];
};

export type TaxonomyGroupType = {
	__typename?: 'TaxonomyGroupType';
	children: Array<TaxonomyGroupType>;
	client: ClientType;
	created_at: Scalars['String'];
	description: Scalars['String'];
	id: Scalars['ID'];
	name: Scalars['String'];
	parent: TaxonomyGroupType;
	taxonomies: Array<TaxonomyType>;
	updated_at: Scalars['String'];
};

export type TaxonomyTaxonomyType = {
	__typename?: 'TaxonomyTaxonomyType';
	child: TaxonomyType;
	child_relations: Array<TaxonomyTaxonomyType>;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	parent: TaxonomyType;
	parent_relation: TaxonomyTaxonomyType;
	subjects: Array<SubjectType>;
	updated_at: Scalars['String'];
};

export type TaxonomyType = {
	__typename?: 'TaxonomyType';
	children: Array<TaxonomyTaxonomyType>;
	created_at: Scalars['String'];
	description: Scalars['String'];
	group: TaxonomyGroupType;
	id: Scalars['ID'];
	name: Scalars['String'];
	parents: Array<TaxonomyTaxonomyType>;
	subjects: Array<SubjectTaxonomyType>;
	updated_at: Scalars['String'];
	users: Array<UserTaxonomyType>;
};

export type TeacherSubjectType = {
	__typename?: 'TeacherSubjectType';
	created_at: Scalars['String'];
	id: Scalars['ID'];
	owner?: Maybe<Scalars['Boolean']>;
	subject?: Maybe<SubjectType>;
	teacher?: Maybe<TeacherType>;
	updated_at: Scalars['String'];
};

export type TeacherType = {
	__typename?: 'TeacherType';
	ai_chat_expenses?: Maybe<Array<AiChatExpense>>;
	ai_chat_subscription_teacher?: Maybe<Array<AiChatSubscriptionTeacher>>;
	authType: Scalars['String'];
	bibliography?: Maybe<Array<Bibliography>>;
	created_at: Scalars['String'];
	id: Scalars['ID'];
	profile_picture?: Maybe<FileType>;
	subjects: Array<TeacherSubjectType>;
	subscriptions: Array<Subscription>;
	updated_at: Scalars['String'];
	user: UserType;
};

export type TextAnswerResults = {
	__typename?: 'TextAnswerResults';
	step: StepType;
	stepId: Scalars['Float'];
	stepResultId: Scalars['Float'];
	step_result: StepResultType;
	value: Scalars['String'];
};

export type TrajectoryResumeType = {
	__typename?: 'TrajectoryResumeType';
	negativeResults?: Maybe<Array<CaseResultType>>;
	positiveResults?: Maybe<Array<CaseResultType>>;
};

export type UpdateAcademicObjectDto = {
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['ID']>;
	prompt?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type UpdateAcademicProductAnswerDto = {
	academic_product?: InputMaybe<Scalars['Float']>;
	academic_product_answer_review?: InputMaybe<
		Array<CreateAcademicProductAnswerReviewDto>
	>;
	bibliography?: InputMaybe<Scalars['String']>;
	client?: InputMaybe<Scalars['Float']>;
	content?: InputMaybe<Scalars['String']>;
	id: Scalars['Float'];
	module?: InputMaybe<Scalars['String']>;
	subject?: InputMaybe<Scalars['Float']>;
	topic?: InputMaybe<Scalars['String']>;
};

export type UpdateAiChatSubscriptionInput = {
	/** Example field (placeholder) */
	exampleField?: InputMaybe<Scalars['Int']>;
	id: Scalars['Int'];
};

export type UpdateCaseDto = {
	caseStatus?: InputMaybe<Scalars['Float']>;
	caseType?: InputMaybe<Scalars['Float']>;
	client?: InputMaybe<Scalars['Float']>;
	create_from?: InputMaybe<CreateFrom>;
	date_from?: InputMaybe<Scalars['String']>;
	date_to?: InputMaybe<Scalars['String']>;
	description?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Float']>;
	name?: InputMaybe<Scalars['String']>;
	order?: InputMaybe<Scalars['Float']>;
	parent?: InputMaybe<Scalars['Float']>;
	questionnaireSettings?: InputMaybe<UpdateQuestionnaireSettingDto>;
	specific_learning_goal?: InputMaybe<Scalars['String']>;
	steps?: InputMaybe<Array<UpdateStepDto>>;
	subject?: InputMaybe<Scalars['Float']>;
	time?: InputMaybe<Scalars['Float']>;
	topic?: InputMaybe<Scalars['String']>;
};

export type UpdateClientDto = {
	id?: InputMaybe<Scalars['Float']>;
	logo_image?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

export type UpdateConfigDto = {
	client?: InputMaybe<Scalars['Int']>;
	configGroup?: InputMaybe<Scalars['Int']>;
	description?: InputMaybe<Scalars['String']>;
	parameters?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
	value?: InputMaybe<Scalars['String']>;
};

export type UpdateConfigGroupDto = {
	description?: InputMaybe<Scalars['String']>;
	title?: InputMaybe<Scalars['String']>;
};

export type UpdateFileInput = {
	base64?: InputMaybe<Scalars['String']>;
	contentUrl?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Float']>;
};

export type UpdateJustificationDto = {
	description?: InputMaybe<Scalars['String']>;
	files?: InputMaybe<Array<UpdateFileInput>>;
	id?: InputMaybe<Scalars['Float']>;
	step?: InputMaybe<Scalars['Float']>;
};

export type UpdateQuestionnaireSettingDto = {
	aleatory_options?: InputMaybe<Scalars['Boolean']>;
	case?: InputMaybe<Scalars['Int']>;
	id: Scalars['Float'];
	is_math?: InputMaybe<Scalars['Boolean']>;
	show_correct_option?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateStepDto = {
	custom_time?: InputMaybe<Scalars['Float']>;
	files?: InputMaybe<Array<UpdateFileInput>>;
	id?: InputMaybe<Scalars['Float']>;
	is_math?: InputMaybe<Scalars['Boolean']>;
	justification?: InputMaybe<UpdateJustificationDto>;
	open_field?: InputMaybe<Scalars['Boolean']>;
	order?: InputMaybe<Scalars['Float']>;
	stepType?: InputMaybe<Scalars['Float']>;
	step_options?: InputMaybe<Array<UpdateStepOptionDto>>;
	title?: InputMaybe<Scalars['String']>;
};

export type UpdateStepOptionDto = {
	correct?: InputMaybe<Scalars['Boolean']>;
	files?: InputMaybe<Array<UpdateFileInput>>;
	id?: InputMaybe<Scalars['Float']>;
	is_math?: InputMaybe<Scalars['Boolean']>;
	step?: InputMaybe<Scalars['Float']>;
	title?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentDto = {
	authType: Scalars['String'];
	client?: InputMaybe<Scalars['Int']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_confirmed?: InputMaybe<Scalars['Boolean']>;
	is_first_use?: InputMaybe<Scalars['Boolean']>;
	last_name?: InputMaybe<Scalars['String']>;
	national_identification?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	phone_number?: InputMaybe<Scalars['String']>;
	profile_picture?: InputMaybe<UpdateFileInput>;
	taxonomies?: InputMaybe<Array<CreateTaxonomyUserDto>>;
	user: Scalars['Float'];
};

export type UpdateSubjectDto = {
	client?: InputMaybe<Scalars['Int']>;
	code?: InputMaybe<Scalars['String']>;
	date_from?: InputMaybe<Scalars['DateTime']>;
	date_to?: InputMaybe<Scalars['DateTime']>;
	description?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	student_ammount?: InputMaybe<Scalars['Int']>;
	subject_status?: InputMaybe<Scalars['Int']>;
	taxonomies?: InputMaybe<CreateTaxonomySubjectDto>;
};

export type UpdateTaxonomyEntityDto = {
	alias?: InputMaybe<Scalars['String']>;
	entity_name?: InputMaybe<Scalars['String']>;
	taxonomy_groups?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateTeacherDto = {
	authType?: InputMaybe<Scalars['String']>;
	client?: InputMaybe<Scalars['Int']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_confirmed?: InputMaybe<Scalars['Boolean']>;
	is_first_use?: InputMaybe<Scalars['Boolean']>;
	last_name?: InputMaybe<Scalars['String']>;
	national_identification?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	phone_number?: InputMaybe<Scalars['String']>;
	profile_picture?: InputMaybe<UpdateFileInput>;
	taxonomies?: InputMaybe<Array<CreateTaxonomyUserDto>>;
};

export type UpdateUserDto = {
	client?: InputMaybe<Scalars['Int']>;
	email?: InputMaybe<Scalars['String']>;
	first_name?: InputMaybe<Scalars['String']>;
	is_active?: InputMaybe<Scalars['Boolean']>;
	is_confirmed?: InputMaybe<Scalars['Boolean']>;
	is_first_use?: InputMaybe<Scalars['Boolean']>;
	last_name?: InputMaybe<Scalars['String']>;
	national_identification?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	phone_number?: InputMaybe<Scalars['String']>;
	profile_picture?: InputMaybe<CreateFileInput>;
	taxonomies?: InputMaybe<Array<CreateTaxonomyUserDto>>;
};

export type UploadObjectResponse = {
	__typename?: 'UploadObjectResponse';
	url: Scalars['String'];
};

export type UserTaxonomy = {
	__typename?: 'UserTaxonomy';
	parent_relation: Scalars['Int'];
	taxonomy: TaxonomyType;
	user: Scalars['Int'];
};

export type UserTaxonomyType = {
	__typename?: 'UserTaxonomyType';
	created_at: Scalars['String'];
	id: Scalars['ID'];
	parent_relation: TaxonomyTaxonomyType;
	taxonomy: TaxonomyType;
	updated_at: Scalars['String'];
	user: UserType;
};

export type UserType = {
	__typename?: 'UserType';
	client: ClientType;
	created_at: Scalars['String'];
	email: Scalars['String'];
	first_name: Scalars['String'];
	id: Scalars['ID'];
	is_active: Scalars['Boolean'];
	is_confirmed: Scalars['Boolean'];
	is_first_use: Scalars['Boolean'];
	last_login?: Maybe<Scalars['DateTime']>;
	last_name: Scalars['String'];
	national_identification?: Maybe<Scalars['String']>;
	password: Scalars['String'];
	phone_number?: Maybe<Scalars['String']>;
	student: StudentType;
	taxonomies: Array<UserTaxonomy>;
	teacher: TeacherType;
	updated_at: Scalars['String'];
	uuids: Array<UuidType>;
};

export type UuidType = {
	__typename?: 'UuidType';
	created_at: Scalars['String'];
	id: Scalars['ID'];
	is_used: Scalars['Boolean'];
	updated_at: Scalars['String'];
	user: UserType;
	uuid: Scalars['String'];
};

export type GetAiChatSubscriptionDataQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetAiChatSubscriptionDataQuery = {
	__typename?: 'Query';
	getTeacherAIEnergy: {
		__typename?: 'AiChatSubscriptionType';
		remainingEnergy: number;
		subscriptionName?: string | null;
	};
};

export type AddExpenseMutationVariables = Exact<{
	value: Scalars['Float'];
}>;

export type AddExpenseMutation = {
	__typename?: 'Mutation';
	addExpense: { __typename?: 'AiChatExpense'; id: string };
};

export type PostAcademicProductAnswerMutationVariables = Exact<{
	data: CreateAcademicProductAnswerDto;
}>;

export type PostAcademicProductAnswerMutation = {
	__typename?: 'Mutation';
	postAcademicProductAnswer: {
		__typename?: 'AcademicProductAnswer';
		id: string;
	};
};

export type DeleteAcademicProductAnswerMutationVariables = Exact<{
	academicProductAnswerId: Scalars['Float'];
}>;

export type DeleteAcademicProductAnswerMutation = {
	__typename?: 'Mutation';
	deleteAcademicProductAnswer: boolean;
};

export type UpdateAcademicProductAnswerMutationVariables = Exact<{
	data: UpdateAcademicProductAnswerDto;
}>;

export type UpdateAcademicProductAnswerMutation = {
	__typename?: 'Mutation';
	updateAcademicProductAnswer: {
		__typename?: 'AcademicProductAnswer';
		id: string;
	};
};

export type GetAcademicObjectsForSubjectQueryVariables = Exact<{
	subjectId: Scalars['Int'];
}>;

export type GetAcademicObjectsForSubjectQuery = {
	__typename?: 'Query';
	getAcademicObjectsForSubject: Array<{
		__typename?: 'AcademicObject';
		id: string;
		title: string;
		description: string;
	}>;
};

export type GetSubjectAcademicObjectAnswersQueryVariables = Exact<{
	subjectId: Scalars['Float'];
}>;

export type GetSubjectAcademicObjectAnswersQuery = {
	__typename?: 'Query';
	getSubjectAcademicObjectAnswers: Array<{
		__typename?: 'AcademicProductAnswer';
		id: string;
		content: string;
		module: string;
		topic: string;
		bibliography: string;
		academic_product_answer_review: Array<{
			__typename?: 'AcademicProductAnswerReview';
			id: string;
			question: string;
			type_name: string;
			value: string;
		}>;
		subject: { __typename?: 'SubjectType'; name: string };
		academic_object?: {
			__typename?: 'AcademicObject';
			id: string;
			title: string;
		} | null;
	}>;
};

export type PostBibliographyWithChaptersMutationVariables = Exact<{
	payload: BibliographyDataDto;
}>;

export type PostBibliographyWithChaptersMutation = {
	__typename?: 'Mutation';
	postBibliographyWithChapters: { __typename?: 'Bibliography'; id: string };
};

export type GetBibliographyQueryVariables = Exact<{ [key: string]: never }>;

export type GetBibliographyQuery = {
	__typename?: 'Query';
	getTeacherBibliography: Array<{
		__typename?: 'Bibliography';
		name: string;
		id: string;
		chapter: Array<{ __typename?: 'Chapter'; name: string }>;
	}>;
};

export type DeleteBibliographyByIdMutationVariables = Exact<{
	deleteBibliographyByIdId: Scalars['Float'];
}>;

export type DeleteBibliographyByIdMutation = {
	__typename?: 'Mutation';
	deleteBibliographyById: string;
};

export type GetClientSettingsQueryVariables = Exact<{
	getClientByIdId: Scalars['Int'];
}>;

export type GetClientSettingsQuery = {
	__typename?: 'Query';
	GetClientById: {
		__typename?: 'ClientType';
		settings: any;
		logo_image: string;
	};
};

export type GetErrorByCodeQueryVariables = Exact<{ [key: string]: never }>;

export type GetErrorByCodeQuery = { __typename?: 'Query'; getError: string };

export type PostCaseMutationVariables = Exact<{
	data: CreateCaseDto;
	caseType: Scalars['String'];
}>;

export type PostCaseMutation = {
	__typename?: 'Mutation';
	postCase: { __typename?: 'CaseType'; id: string };
};

export type DeleteQuestionnairesMutationVariables = Exact<{
	ids: Array<Scalars['Float']> | Scalars['Float'];
	caseType: Scalars['String'];
}>;

export type DeleteQuestionnairesMutation = {
	__typename?: 'Mutation';
	deleteCases: string;
};

export type PutCaseMutationVariables = Exact<{
	id: Scalars['Float'];
	data: UpdateCaseDto;
}>;

export type PutCaseMutation = {
	__typename?: 'Mutation';
	putCase: { __typename?: 'CaseType'; id: string };
};

export type DuplicateCaseMutationVariables = Exact<{
	caseId: Scalars['Float'];
}>;

export type DuplicateCaseMutation = {
	__typename?: 'Mutation';
	duplicateCase: { __typename?: 'CaseType'; id: string };
};

export type GetQuestionnairesBySubjectQueryVariables = Exact<{
	caseType: Scalars['String'];
	subjectId: Scalars['Float'];
}>;

export type GetQuestionnairesBySubjectQuery = {
	__typename?: 'Query';
	getQuestionnairesBySubject: {
		__typename?: 'CaseTypeWithPermissions';
		globalPermissions?: Array<string> | null;
		data?: Array<{
			__typename?: 'CaseType';
			id: string;
			create_from: CreateFrom;
			description?: string | null;
			name: string;
			order?: number | null;
			time?: number | null;
			topic?: string | null;
			dateFrom: string;
			dateTo: string;
			results?: {
				__typename?: 'CaseResultMetrics';
				responseAmmount?: number | null;
				averageScore?: number | null;
				responseRate?: number | null;
			} | null;
			caseStatus: { __typename?: 'CaseStatusType'; id: string; status: string };
		}> | null;
	};
};

export type GetCaseByIdQueryVariables = Exact<{
	questionnaireId: Scalars['Float'];
}>;

export type GetCaseByIdQuery = {
	__typename?: 'Query';
	getCaseByID: {
		__typename?: 'CaseType';
		id: string;
		create_from: CreateFrom;
		description?: string | null;
		name: string;
		order?: number | null;
		time?: number | null;
		topic?: string | null;
		dateFrom: string;
		dateTo: string;
		results?: {
			__typename?: 'CaseResultMetrics';
			averageScore?: number | null;
			studentAmount?: number | null;
			responseRate?: number | null;
		} | null;
		questionnaire_setting?: {
			__typename?: 'QuestionnaireSettingType';
			id: string;
			aleatory_options: boolean;
			show_correct_option: boolean;
		} | null;
		subject: {
			__typename?: 'SubjectType';
			id: string;
			student_ammount?: number | null;
		};
		questions?: Array<{
			__typename?: 'StepType';
			id: string;
			is_math: boolean;
			custom_time?: number | null;
			created_at: string;
			name: string;
			step_type: { __typename?: 'StepTypeType'; id: string };
			files?: Array<{
				__typename?: 'FileType';
				bucket_name: string;
				name: string;
			}> | null;
			justification?: {
				__typename?: 'JustificationType';
				id: string;
				description: string;
				files: Array<{
					__typename?: 'FileType';
					bucket_name: string;
					name: string;
				}>;
			} | null;
			options?: Array<{
				__typename?: 'StepOptionType';
				id: string;
				is_math: boolean;
				created_at: string;
				isCorrect?: boolean | null;
				text: string;
			}> | null;
		}> | null;
	};
};

export type GetQuestionnaireStatisticsQueryVariables = Exact<{
	questionnaireId: Scalars['Float'];
}>;

export type GetQuestionnaireStatisticsQuery = {
	__typename?: 'Query';
	getCaseByID: {
		__typename?: 'CaseType';
		id: string;
		create_from: CreateFrom;
		description?: string | null;
		name: string;
		order?: number | null;
		time?: number | null;
		topic?: string | null;
		dateFrom: string;
		dateTo: string;
		caseReviews: Array<{
			__typename?: 'CaseReviewType';
			id: string;
			value: number;
		}>;
		questionnaire_setting?: {
			__typename?: 'QuestionnaireSettingType';
			id: string;
			aleatory_options: boolean;
			show_correct_option: boolean;
		} | null;
		subject: { __typename?: 'SubjectType'; id: string };
		caseResults: Array<{
			__typename?: 'CaseResultType';
			id: string;
			score: number;
			created_at: string;
			student: { __typename?: 'StudentType'; id: string };
		}>;
		results?: {
			__typename?: 'CaseResultMetrics';
			averageScore?: number | null;
		} | null;
		questions?: Array<{
			__typename?: 'StepType';
			id: string;
			is_math: boolean;
			created_at: string;
			name: string;
			files?: Array<{
				__typename?: 'FileType';
				bucket_name: string;
				name: string;
			}> | null;
			step_type: { __typename?: 'StepTypeType'; id: string };
			justification?: {
				__typename?: 'JustificationType';
				id: string;
				description: string;
				files: Array<{
					__typename?: 'FileType';
					bucket_name: string;
					name: string;
				}>;
			} | null;
			options?: Array<{
				__typename?: 'StepOptionType';
				id: string;
				is_math: boolean;
				created_at: string;
				isCorrect?: boolean | null;
				text: string;
			}> | null;
			results?: Array<{
				__typename?: 'StepResultType';
				id: string;
				created_at: string;
				out_of_time: boolean;
				correct: boolean;
				time_spent: number;
				step_option_results: Array<{
					__typename?: 'StepOptionResultType';
					step_option?: { __typename?: 'StepOptionType'; id: string } | null;
				}>;
				student: {
					__typename?: 'StudentType';
					id: string;
					user: {
						__typename?: 'UserType';
						id: string;
						first_name: string;
						last_name: string;
					};
				};
				case: { __typename?: 'CaseType'; id: string };
			}> | null;
		}> | null;
	};
};

export type GetQuestionnaireNotificationsQueryVariables = Exact<{
	questionnaireId: Scalars['Float'];
}>;

export type GetQuestionnaireNotificationsQuery = {
	__typename?: 'Query';
	getQuestionnaireStatus?: {
		__typename?: 'QuestionnaireNotificationType';
		status?: string | null;
	} | null;
};

export type GetCasesBySubjectIdToDashboardQueryVariables = Exact<{
	subjectId: Scalars['Float'];
}>;

export type GetCasesBySubjectIdToDashboardQuery = {
	__typename?: 'Query';
	getCasesBySubjectToDashboard: Array<{
		__typename?: 'CaseType';
		id: string;
		description?: string | null;
		name: string;
		order?: number | null;
		time?: number | null;
		topic?: string | null;
		create_from: CreateFrom;
		dateFrom: string;
		dateTo: string;
		caseReviews: Array<{
			__typename?: 'CaseReviewType';
			id: string;
			value: number;
		}>;
		caseStatus: { __typename?: 'CaseStatusType'; id: string; status: string };
		subject: {
			__typename?: 'SubjectType';
			id: string;
			student_ammount?: number | null;
			studentSubjects?: Array<{
				__typename?: 'StudentSubjectType';
				student?: {
					__typename?: 'StudentType';
					id: string;
					user: {
						__typename?: 'UserType';
						email: string;
						first_name: string;
						last_name: string;
					};
				} | null;
			}> | null;
		};
		caseResults: Array<{
			__typename?: 'CaseResultType';
			id: string;
			score: number;
			created_at: string;
			student: { __typename?: 'StudentType'; id: string };
		}>;
		results?: {
			__typename?: 'CaseResultMetrics';
			studentAmount?: number | null;
			averageScore?: number | null;
			responseRate?: number | null;
		} | null;
		caseType: { __typename?: 'CaseType'; id: string };
	}>;
};

export type DuplicateCaseToSubjectMutationVariables = Exact<{
	caseId: Scalars['Float'];
	subjectId: Scalars['Float'];
}>;

export type DuplicateCaseToSubjectMutation = {
	__typename?: 'Mutation';
	duplicateCaseToSubject: { __typename?: 'CaseType'; id: string };
};

export type QuestionnaireAttributesFragment = {
	__typename?: 'CaseType';
	id: string;
	create_from: CreateFrom;
	description?: string | null;
	name: string;
	order?: number | null;
	time?: number | null;
	topic?: string | null;
	dateFrom: string;
	dateTo: string;
};

export type GetAcademicObjectsDashboardReportQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetAcademicObjectsDashboardReportQuery = {
	__typename?: 'Query';
	reportToDashboard: Array<any>;
};

export type GetEvaluadosAppDashboardReportQueryVariables = Exact<{
	year: Scalars['String'];
	limit?: InputMaybe<Scalars['Int']>;
	searchArgs?: InputMaybe<Array<SearchArgs> | SearchArgs>;
	offset?: InputMaybe<Scalars['Int']>;
	searchQuery?: InputMaybe<Scalars['String']>;
}>;

export type GetEvaluadosAppDashboardReportQuery = {
	__typename?: 'Query';
	reportToDashboardEvaluadosApp: any;
};

export type GetEvaluadosAppDashboardReportHeadersQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetEvaluadosAppDashboardReportHeadersQuery = {
	__typename?: 'Query';
	evaluadosAppReportHeaders: any;
};

export type GetEvaluadosAppDashboardReportCardsQueryVariables = Exact<{
	[key: string]: never;
}>;

export type GetEvaluadosAppDashboardReportCardsQuery = {
	__typename?: 'Query';
	getEvaluadosAppReportCards: any;
};

export type GetSubjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubjectsQuery = {
	__typename?: 'Query';
	getSubjects: {
		__typename?: 'SubjectTypeWithPermissions';
		globalPermissions: Array<string>;
		data: Array<{
			__typename?: 'SubjectType';
			id: string;
			code: string;
			name: string;
			description?: string | null;
			permissions?: Array<string> | null;
			averageScore?: number | null;
			createdAt: any;
			updatedAt: any;
			studentAmmount?: number | null;
			dateFrom: any;
			dateTo: any;
		}>;
	};
};

export type PostSubjectMutationVariables = Exact<{
	subject: CreateSubjectDto;
}>;

export type PostSubjectMutation = {
	__typename?: 'Mutation';
	postSubject: { __typename?: 'SubjectType'; id: string };
};

export type DeleteCaseResultBySubjectMutationVariables = Exact<{
	subjectId: Scalars['Float'];
}>;

export type DeleteCaseResultBySubjectMutation = {
	__typename?: 'Mutation';
	deleteCaseResultBySubject: string;
};

export type DeleteSubjectByIdMutationVariables = Exact<{
	id: Scalars['Float'];
}>;

export type DeleteSubjectByIdMutation = {
	__typename?: 'Mutation';
	deleteSubjectById: string;
};

export type GetSubjectByIdQueryVariables = Exact<{
	id: Scalars['Float'];
}>;

export type GetSubjectByIdQuery = {
	__typename?: 'Query';
	getSubjectByID: {
		__typename?: 'SubjectType';
		id: string;
		code: string;
		name: string;
		permissions?: Array<string> | null;
		description?: string | null;
		student_ammount?: number | null;
		createdAt: any;
		dateFrom: any;
		dateTo: any;
		updatedAt: any;
	};
};

export type GetSubjectStudentsQueryVariables = Exact<{
	subjectId: Scalars['Float'];
}>;

export type GetSubjectStudentsQuery = {
	__typename?: 'Query';
	getSubjectStudents: {
		__typename?: 'SubjectType';
		cases: Array<{
			__typename?: 'CaseType';
			id: string;
			name: string;
			description?: string | null;
			caseType: { __typename?: 'CaseType'; id: string };
			caseResults: Array<{
				__typename?: 'CaseResultType';
				id: string;
				time_spent: number;
				score: number;
				created_at: string;
				student: {
					__typename?: 'StudentType';
					id: string;
					profile_picture?: { __typename?: 'FileType'; id: number } | null;
					user: {
						__typename?: 'UserType';
						email: string;
						first_name: string;
						last_name: string;
					};
				};
			}>;
		}>;
	};
};

export type CheckIfSubjectCodeExistsQueryVariables = Exact<{
	code: Scalars['String'];
}>;

export type CheckIfSubjectCodeExistsQuery = {
	__typename?: 'Query';
	checkSubjectCode: boolean;
};

export type UpdateSubjectMutationVariables = Exact<{
	data: UpdateSubjectDto;
	id: Scalars['Float'];
}>;

export type UpdateSubjectMutation = {
	__typename?: 'Mutation';
	updateSubject: { __typename?: 'SubjectType'; id: string };
};

export type DuplicateSubjectWithContentsMutationVariables = Exact<{
	subjectId: Scalars['Float'];
	data?: InputMaybe<UpdateSubjectDto>;
}>;

export type DuplicateSubjectWithContentsMutation = {
	__typename?: 'Mutation';
	duplicateSubjectWithContents: { __typename?: 'SubjectType'; id: string };
};

export type GetTeacherSyllabusQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeacherSyllabusQuery = {
	__typename?: 'Query';
	getTeacherSyllabus: Array<{
		__typename?: 'SyllabusStructure';
		id: string;
		name: string;
		type: SyllabusStructureType;
		complementary_bibliography: Array<string>;
		bibliography: Array<string>;
		learning_goals: Array<string>;
		syllabus_structure_children: any;
		subject?: { __typename?: 'SubjectType'; id: string } | null;
		syllabus_files?: Array<{
			__typename?: 'SyllabusFiles';
			fileId: number;
			file?: { __typename?: 'File'; name: string } | null;
		}> | null;
	}>;
};

export type GetSubjectSyllabusQueryVariables = Exact<{
	subjectId: Scalars['Float'];
}>;

export type GetSubjectSyllabusQuery = {
	__typename?: 'Query';
	getSubjectSyllabus: {
		__typename?: 'SyllabusStructure';
		id: string;
		name: string;
		type: SyllabusStructureType;
		complementary_bibliography: Array<string>;
		bibliography: Array<string>;
		learning_goals: Array<string>;
		syllabus_structure_children: any;
	};
};

export type GetSurveysBySubjectQueryVariables = Exact<{
	caseType: Scalars['String'];
	subjectId: Scalars['Float'];
}>;

export type GetSurveysBySubjectQuery = {
	__typename?: 'Query';
	getSurveysBySubject: {
		__typename?: 'CaseTypeWithPermissions';
		globalPermissions?: Array<string> | null;
		data?: Array<{
			__typename?: 'CaseType';
			id: string;
			name: string;
			topic?: string | null;
			description?: string | null;
			dateFrom: string;
			dateTo: string;
			results?: {
				__typename?: 'CaseResultMetrics';
				responseAmmount?: number | null;
				responseRate?: number | null;
			} | null;
		}> | null;
	};
};

export type GetSurveyByIdQueryVariables = Exact<{
	id: Scalars['Float'];
}>;

export type GetSurveyByIdQuery = {
	__typename?: 'Query';
	getCaseByID: {
		__typename?: 'CaseType';
		id: string;
		date_from: string;
		date_to: string;
		description?: string | null;
		name: string;
		subject: { __typename?: 'SubjectType'; id: string };
		steps?: Array<{
			__typename?: 'StepType';
			open_field?: boolean | null;
			created_at: string;
			id: string;
			title: string;
			step_results?: Array<{
				__typename?: 'StepResultType';
				id: string;
			}> | null;
			step_type: { __typename?: 'StepTypeType'; id: string };
			step_options?: Array<{
				__typename?: 'StepOptionType';
				id: string;
				title: string;
				created_at: string;
			}> | null;
		}> | null;
	};
};

export type GetSurveyResultsQueryVariables = Exact<{
	surveyId: Scalars['Float'];
}>;

export type GetSurveyResultsQuery = {
	__typename?: 'Query';
	getCaseByID: {
		__typename?: 'CaseType';
		id: string;
		name: string;
		date_from: string;
		date_to: string;
		description?: string | null;
		order?: number | null;
		subject: { __typename?: 'SubjectType'; id: string };
		steps?: Array<{
			__typename?: 'StepType';
			open_field?: boolean | null;
			id: string;
			title: string;
			is_math: boolean;
			created_at: string;
			step_results?: Array<{
				__typename?: 'StepResultType';
				id: string;
				created_at: string;
				out_of_time: boolean;
				time_spent: number;
				text_answer_results: Array<{
					__typename?: 'TextAnswerResults';
					value: string;
				}>;
				step_option_results: Array<{
					__typename?: 'StepOptionResultType';
					step_option?: { __typename?: 'StepOptionType'; id: string } | null;
				}>;
				student: {
					__typename?: 'StudentType';
					id: string;
					user: {
						__typename?: 'UserType';
						id: string;
						first_name: string;
						last_name: string;
						email: string;
					};
				};
			}> | null;
			justification?: {
				__typename?: 'JustificationType';
				description: string;
			} | null;
			step_type: { __typename?: 'StepTypeType'; id: string; name: string };
			step_options?: Array<{
				__typename?: 'StepOptionType';
				id: string;
				is_math: boolean;
				title: string;
				created_at: string;
			}> | null;
		}> | null;
	};
};

export type PostSurveyMutationVariables = Exact<{
	data: CreateCaseDto;
}>;

export type PostSurveyMutation = {
	__typename?: 'Mutation';
	postCase: { __typename?: 'CaseType'; id: string };
};

export type DeleteSurveysMutationVariables = Exact<{
	ids: Array<Scalars['Float']> | Scalars['Float'];
}>;

export type DeleteSurveysMutation = {
	__typename?: 'Mutation';
	deleteCases: string;
};

export type DuplicateSurveyMutationVariables = Exact<{
	caseId: Scalars['Float'];
}>;

export type DuplicateSurveyMutation = {
	__typename?: 'Mutation';
	duplicateCase: { __typename?: 'CaseType'; id: string };
};

export type UpdateSurveyMutationVariables = Exact<{
	id: Scalars['Float'];
	data: UpdateCaseDto;
}>;

export type UpdateSurveyMutation = {
	__typename?: 'Mutation';
	putCase: { __typename?: 'CaseType'; id: string };
};

export type GetSurveyStatisticsQueryVariables = Exact<{
	questionnaireId: Scalars['Float'];
}>;

export type GetSurveyStatisticsQuery = {
	__typename?: 'Query';
	getCaseByID: {
		__typename?: 'CaseType';
		id: string;
		description?: string | null;
		name: string;
		order?: number | null;
		topic?: string | null;
		dateFrom: string;
		dateTo: string;
		questionnaire_setting?: {
			__typename?: 'QuestionnaireSettingType';
			id: string;
			aleatory_options: boolean;
		} | null;
		subject: { __typename?: 'SubjectType'; id: string };
		questions?: Array<{
			__typename?: 'StepType';
			id: string;
			is_math: boolean;
			name: string;
			files?: Array<{
				__typename?: 'FileType';
				bucket_name: string;
				name: string;
			}> | null;
			step_type: { __typename?: 'StepTypeType'; id: string };
			justification?: {
				__typename?: 'JustificationType';
				id: string;
				description: string;
				files: Array<{
					__typename?: 'FileType';
					bucket_name: string;
					name: string;
				}>;
			} | null;
			options?: Array<{
				__typename?: 'StepOptionType';
				id: string;
				is_math: boolean;
				text: string;
			}> | null;
			results?: Array<{
				__typename?: 'StepResultType';
				id: string;
				created_at: string;
				step_option_results: Array<{
					__typename?: 'StepOptionResultType';
					step_option?: { __typename?: 'StepOptionType'; id: string } | null;
				}>;
				student: {
					__typename?: 'StudentType';
					id: string;
					user: {
						__typename?: 'UserType';
						id: string;
						first_name: string;
						last_name: string;
					};
				};
			}> | null;
		}> | null;
	};
};

export type LoginWebMutationVariables = Exact<{
	email: Scalars['String'];
	password: Scalars['String'];
	client: Scalars['Int'];
}>;

export type LoginWebMutation = {
	__typename?: 'Mutation';
	loginweb: {
		__typename?: 'LoginType';
		last_name: string;
		national_identification?: string | null;
		id: string;
		phone_number?: string | null;
		first_name: string;
		email: string;
		token: string;
		is_first_use: boolean;
		teacher: {
			__typename?: 'TeacherType';
			id: string;
			profile_picture?: {
				__typename?: 'FileType';
				path: string;
				bucket_name: string;
			} | null;
			ai_chat_expenses?: Array<{
				__typename?: 'AiChatExpense';
				amount: number;
			}> | null;
			ai_chat_subscription_teacher?: Array<{
				__typename?: 'AiChatSubscriptionTeacher';
				ai_chat_subscription: {
					__typename?: 'AiChatSubscription';
					name: string;
					value: number;
				};
			}> | null;
		};
	};
};

export type ConfirmEmailMutationVariables = Exact<{
	token: Scalars['String'];
}>;

export type ConfirmEmailMutation = {
	__typename?: 'Mutation';
	confirmEmail: {
		__typename?: 'UserType';
		id: string;
		first_name: string;
		last_name: string;
		is_active: boolean;
		is_first_use: boolean;
		email: string;
		teacher: {
			__typename?: 'TeacherType';
			id: string;
			profile_picture?: { __typename?: 'FileType'; path: string } | null;
		};
	};
};

export type RecoveryNewPasswordMutationVariables = Exact<{
	token: Scalars['String'];
	password: Scalars['String'];
}>;

export type RecoveryNewPasswordMutation = {
	__typename?: 'Mutation';
	resetPassword: string;
};

export type RegisterMutationVariables = Exact<{
	first_name: Scalars['String'];
	last_name: Scalars['String'];
	email: Scalars['String'];
	password: Scalars['String'];
	national_identification: Scalars['String'];
	phone_number: Scalars['String'];
	client: Scalars['Int'];
}>;

export type RegisterMutation = {
	__typename?: 'Mutation';
	register: {
		__typename?: 'TeacherType';
		user: {
			__typename?: 'UserType';
			id: string;
			first_name: string;
			last_name: string;
			is_active: boolean;
		};
	};
};

export type CheckIfEmailExistsQueryVariables = Exact<{
	email: Scalars['String'];
}>;

export type CheckIfEmailExistsQuery = {
	__typename?: 'Query';
	checkEmail: boolean;
};

export type GetRoutersForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoutersForUserQuery = {
	__typename?: 'Query';
	getRoutersForUser: Array<string>;
};

export type SendRecoveryPasswordEmailMutationVariables = Exact<{
	email: Scalars['String'];
}>;

export type SendRecoveryPasswordEmailMutation = {
	__typename?: 'Mutation';
	forgotPassword: string;
};

export type TeacherGoogleLoginMutationVariables = Exact<{
	code: Scalars['String'];
	client: Scalars['Float'];
}>;

export type TeacherGoogleLoginMutation = {
	__typename?: 'Mutation';
	teacherGoogleLogin: {
		__typename?: 'LoginType';
		id: string;
		first_name: string;
		last_name: string;
		email: string;
		is_first_use: boolean;
		token: string;
		national_identification?: string | null;
		phone_number?: string | null;
		teacher: {
			__typename?: 'TeacherType';
			id: string;
			profile_picture?: {
				__typename?: 'FileType';
				path: string;
				bucket_name: string;
			} | null;
			ai_chat_expenses?: Array<{
				__typename?: 'AiChatExpense';
				amount: number;
			}> | null;
			ai_chat_subscription_teacher?: Array<{
				__typename?: 'AiChatSubscriptionTeacher';
				ai_chat_subscription: {
					__typename?: 'AiChatSubscription';
					name: string;
					value: number;
				};
			}> | null;
		};
	};
};

export type RefreshTeacherTokenMutationVariables = Exact<{
	refreshToken: Scalars['String'];
}>;

export type RefreshTeacherTokenMutation = {
	__typename?: 'Mutation';
	refreshTeacherToken: {
		__typename?: 'LoginType';
		id: string;
		first_name: string;
		last_name: string;
		email: string;
		is_first_use: boolean;
		token: string;
		national_identification?: string | null;
		phone_number?: string | null;
		teacher: {
			__typename?: 'TeacherType';
			id: string;
			profile_picture?: {
				__typename?: 'FileType';
				path: string;
				bucket_name: string;
			} | null;
			ai_chat_expenses?: Array<{
				__typename?: 'AiChatExpense';
				amount: number;
			}> | null;
			ai_chat_subscription_teacher?: Array<{
				__typename?: 'AiChatSubscriptionTeacher';
				ai_chat_subscription: {
					__typename?: 'AiChatSubscription';
					name: string;
					value: number;
				};
			}> | null;
		};
	};
};

export type TeacherFieldsFragment = {
	__typename?: 'LoginType';
	teacher: {
		__typename?: 'TeacherType';
		id: string;
		profile_picture?: {
			__typename?: 'FileType';
			path: string;
			bucket_name: string;
		} | null;
		ai_chat_expenses?: Array<{
			__typename?: 'AiChatExpense';
			amount: number;
		}> | null;
		ai_chat_subscription_teacher?: Array<{
			__typename?: 'AiChatSubscriptionTeacher';
			ai_chat_subscription: {
				__typename?: 'AiChatSubscription';
				name: string;
				value: number;
			};
		}> | null;
	};
};

export type PutPhotoUserEditMutationVariables = Exact<{
	changes: UpdateTeacherDto;
	putTeacherId: Scalars['Int'];
}>;

export type PutPhotoUserEditMutation = {
	__typename?: 'Mutation';
	putTeacher: {
		__typename?: 'TeacherType';
		id: string;
		profile_picture?: {
			__typename?: 'FileType';
			path: string;
			id: number;
			bucket_name: string;
		} | null;
	};
};

export type UpdateUserMutationVariables = Exact<{
	updateUsersId: Scalars['Int'];
	data: UpdateUserDto;
}>;

export type UpdateUserMutation = {
	__typename?: 'Mutation';
	updateUsers: {
		__typename?: 'UserType';
		is_first_use: boolean;
		is_confirmed: boolean;
		is_active: boolean;
		id: string;
		first_name: string;
		email: string;
		phone_number?: string | null;
		national_identification?: string | null;
		last_name: string;
		client: { __typename?: 'ClientType'; id: string };
	};
};

export type IsExpiredTokenMutationVariables = Exact<{
	token: Scalars['String'];
}>;

export type IsExpiredTokenMutation = {
	__typename?: 'Mutation';
	verifyTokenExpiration: boolean;
};

export const QuestionnaireAttributesFragmentDoc = gql`
	fragment QuestionnaireAttributes on CaseType {
		id
		create_from
		dateFrom: date_from
		dateTo: date_to
		description
		name
		order
		time
		topic
	}
`;
export const TeacherFieldsFragmentDoc = gql`
	fragment TeacherFields on LoginType {
		teacher {
			id
			profile_picture {
				path
				bucket_name
			}
			ai_chat_expenses {
				amount
			}
			ai_chat_subscription_teacher {
				ai_chat_subscription {
					name
					value
				}
			}
		}
	}
`;
export const GetAiChatSubscriptionDataDocument = gql`
	query GetAiChatSubscriptionData {
		getTeacherAIEnergy {
			remainingEnergy
			subscriptionName
		}
	}
`;
export const AddExpenseDocument = gql`
	mutation AddExpense($value: Float!) {
		addExpense(value: $value) {
			id
		}
	}
`;
export const PostAcademicProductAnswerDocument = gql`
	mutation PostAcademicProductAnswer($data: CreateAcademicProductAnswerDTO!) {
		postAcademicProductAnswer(data: $data) {
			id
		}
	}
`;
export const DeleteAcademicProductAnswerDocument = gql`
	mutation DeleteAcademicProductAnswer($academicProductAnswerId: Float!) {
		deleteAcademicProductAnswer(
			academicProductAnswerId: $academicProductAnswerId
		)
	}
`;
export const UpdateAcademicProductAnswerDocument = gql`
	mutation UpdateAcademicProductAnswer($data: UpdateAcademicProductAnswerDTO!) {
		updateAcademicProductAnswer(data: $data) {
			id
		}
	}
`;
export const GetAcademicObjectsForSubjectDocument = gql`
	query GetAcademicObjectsForSubject($subjectId: Int!) {
		getAcademicObjectsForSubject(subjectId: $subjectId) {
			id
			title
			description
		}
	}
`;
export const GetSubjectAcademicObjectAnswersDocument = gql`
	query GetSubjectAcademicObjectAnswers($subjectId: Float!) {
		getSubjectAcademicObjectAnswers(subjectId: $subjectId) {
			id
			content
			module
			topic
			bibliography
			academic_product_answer_review {
				id
				question
				type_name
				value
			}
			subject {
				name
			}
			academic_object {
				id
				title
			}
		}
	}
`;
export const PostBibliographyWithChaptersDocument = gql`
	mutation PostBibliographyWithChapters($payload: BibliographyDataDto!) {
		postBibliographyWithChapters(payload: $payload) {
			id
		}
	}
`;
export const GetBibliographyDocument = gql`
	query getBibliography {
		getTeacherBibliography {
			name
			id
			chapter {
				name
			}
		}
	}
`;
export const DeleteBibliographyByIdDocument = gql`
	mutation DeleteBibliographyById($deleteBibliographyByIdId: Float!) {
		deleteBibliographyById(id: $deleteBibliographyByIdId)
	}
`;
export const GetClientSettingsDocument = gql`
	query GetClientSettings($getClientByIdId: Int!) {
		GetClientById(id: $getClientByIdId) {
			settings
			logo_image
		}
	}
`;
export const GetErrorByCodeDocument = gql`
	query GetErrorByCode {
		getError(errorCode: "CLIENT_NOT_FOUND")
	}
`;
export const PostCaseDocument = gql`
	mutation PostCase($data: CreateCaseDto!, $caseType: String!) {
		postCase(data: $data, caseType: $caseType) {
			id
		}
	}
`;
export const DeleteQuestionnairesDocument = gql`
	mutation DeleteQuestionnaires($ids: [Float!]!, $caseType: String!) {
		deleteCases(ids: $ids, caseType: $caseType)
	}
`;
export const PutCaseDocument = gql`
	mutation PutCase($id: Float!, $data: UpdateCaseDto!) {
		putCase(id: $id, data: $data) {
			id
		}
	}
`;
export const DuplicateCaseDocument = gql`
	mutation DuplicateCase($caseId: Float!) {
		duplicateCase(caseId: $caseId) {
			id
		}
	}
`;
export const GetQuestionnairesBySubjectDocument = gql`
	query GetQuestionnairesBySubject($caseType: String!, $subjectId: Float!) {
		getQuestionnairesBySubject(caseType: $caseType, subjectId: $subjectId) {
			data {
				...QuestionnaireAttributes
				results {
					responseAmmount: response_amount
					averageScore: average_score
					responseRate: response_rate
				}
				caseStatus {
					id
					status
				}
			}
			globalPermissions
		}
	}
	${QuestionnaireAttributesFragmentDoc}
`;
export const GetCaseByIdDocument = gql`
	query GetCaseById($questionnaireId: Float!) {
		getCaseByID(id: $questionnaireId) {
			...QuestionnaireAttributes
			results {
				averageScore: average_score
				studentAmount: response_amount
				responseRate: response_rate
			}
			questionnaire_setting {
				id
				aleatory_options
				show_correct_option
			}
			subject {
				id
				student_ammount
			}
			questions: steps {
				id
				name: title
				is_math
				custom_time
				step_type {
					id
				}
				files {
					bucket_name
					name
				}
				justification {
					id
					description
					files {
						bucket_name
						name
					}
				}
				created_at
				options: step_options {
					id
					isCorrect: correct
					is_math
					text: title
					created_at
				}
			}
		}
	}
	${QuestionnaireAttributesFragmentDoc}
`;
export const GetQuestionnaireStatisticsDocument = gql`
	query GetQuestionnaireStatistics($questionnaireId: Float!) {
		getCaseByID(id: $questionnaireId) {
			...QuestionnaireAttributes
			caseReviews {
				id
				value
			}
			questionnaire_setting {
				id
				aleatory_options
				show_correct_option
			}
			subject {
				id
			}
			caseResults {
				id
				score
				created_at
				student {
					id
				}
			}
			results {
				averageScore: average_score
			}
			questions: steps {
				files {
					bucket_name
					name
				}
				id
				is_math
				created_at
				name: title
				step_type {
					id
				}
				justification {
					id
					description
					files {
						bucket_name
						name
					}
				}
				options: step_options {
					id
					isCorrect: correct
					is_math
					text: title
					created_at
				}
				results: step_results {
					id
					created_at
					step_option_results {
						step_option {
							id
						}
					}
					out_of_time
					correct
					time_spent
					student {
						id
						user {
							id
							first_name
							last_name
						}
					}
					case {
						id
					}
				}
			}
		}
	}
	${QuestionnaireAttributesFragmentDoc}
`;
export const GetQuestionnaireNotificationsDocument = gql`
	query GetQuestionnaireNotifications($questionnaireId: Float!) {
		getQuestionnaireStatus(caseId: $questionnaireId) {
			status
		}
	}
`;
export const GetCasesBySubjectIdToDashboardDocument = gql`
	query GetCasesBySubjectIdToDashboard($subjectId: Float!) {
		getCasesBySubjectToDashboard(subjectId: $subjectId) {
			id
			dateFrom: date_from
			dateTo: date_to
			description
			name
			order
			time
			topic
			caseReviews {
				id
				value
			}
			...QuestionnaireAttributes
			caseStatus {
				id
				status
			}
			subject {
				id
				student_ammount
				studentSubjects {
					student {
						id
						user {
							email
							first_name
							last_name
						}
					}
				}
			}
			caseResults {
				id
				score
				created_at
				student {
					id
				}
			}
			results {
				studentAmount: response_amount
				averageScore: average_score
				responseRate: response_rate
			}
			caseReviews {
				value
				id
			}
			caseType {
				id
			}
		}
	}
	${QuestionnaireAttributesFragmentDoc}
`;
export const DuplicateCaseToSubjectDocument = gql`
	mutation DuplicateCaseToSubject($caseId: Float!, $subjectId: Float!) {
		duplicateCaseToSubject(caseId: $caseId, subjectId: $subjectId) {
			id
		}
	}
`;
export const GetAcademicObjectsDashboardReportDocument = gql`
	query GetAcademicObjectsDashboardReport {
		reportToDashboard
	}
`;
export const GetEvaluadosAppDashboardReportDocument = gql`
	query GetEvaluadosAppDashboardReport(
		$year: String!
		$limit: Int
		$searchArgs: [SearchArgs!]
		$offset: Int
		$searchQuery: String
	) {
		reportToDashboardEvaluadosApp(
			year: $year
			limit: $limit
			searchArgs: $searchArgs
			offset: $offset
			searchQuery: $searchQuery
		)
	}
`;
export const GetEvaluadosAppDashboardReportHeadersDocument = gql`
	query GetEvaluadosAppDashboardReportHeaders {
		evaluadosAppReportHeaders
	}
`;
export const GetEvaluadosAppDashboardReportCardsDocument = gql`
	query GetEvaluadosAppDashboardReportCards {
		getEvaluadosAppReportCards
	}
`;
export const GetSubjectsDocument = gql`
	query GetSubjects {
		getSubjects {
			data {
				id
				createdAt: created_at
				updatedAt: updated_at
				code
				name
				description
				permissions
				studentAmmount: student_ammount
				dateFrom: date_from
				dateTo: date_to
				averageScore
			}
			globalPermissions
		}
	}
`;
export const PostSubjectDocument = gql`
	mutation PostSubject($subject: CreateSubjectDto!) {
		postSubject(data: $subject) {
			id
		}
	}
`;
export const DeleteCaseResultBySubjectDocument = gql`
	mutation deleteCaseResultBySubject($subjectId: Float!) {
		deleteCaseResultBySubject(subjectId: $subjectId)
	}
`;
export const DeleteSubjectByIdDocument = gql`
	mutation deleteSubjectById($id: Float!) {
		deleteSubjectById(id: $id)
	}
`;
export const GetSubjectByIdDocument = gql`
	query GetSubjectByID($id: Float!) {
		getSubjectByID(id: $id) {
			id
			code
			name
			permissions
			createdAt: created_at
			dateFrom: date_from
			dateTo: date_to
			updatedAt: updated_at
			description
			student_ammount
		}
	}
`;
export const GetSubjectStudentsDocument = gql`
	query GetSubjectStudents($subjectId: Float!) {
		getSubjectStudents(subjectId: $subjectId) {
			cases {
				id
				name
				description
				caseType {
					id
				}
				caseResults {
					id
					time_spent
					score
					created_at
					student {
						id
						profile_picture {
							id
						}
						user {
							email
							first_name
							last_name
						}
					}
				}
			}
		}
	}
`;
export const CheckIfSubjectCodeExistsDocument = gql`
	query CheckIfSubjectCodeExists($code: String!) {
		checkSubjectCode(code: $code)
	}
`;
export const UpdateSubjectDocument = gql`
	mutation UpdateSubject($data: UpdateSubjectDto!, $id: Float!) {
		updateSubject(data: $data, id: $id) {
			id
		}
	}
`;
export const DuplicateSubjectWithContentsDocument = gql`
	mutation duplicateSubjectWithContents(
		$subjectId: Float!
		$data: UpdateSubjectDto
	) {
		duplicateSubjectWithContents(subjectId: $subjectId, data: $data) {
			id
		}
	}
`;
export const GetTeacherSyllabusDocument = gql`
	query GetTeacherSyllabus {
		getTeacherSyllabus {
			id
			name
			type
			complementary_bibliography
			bibliography
			learning_goals
			syllabus_structure_children
			subject {
				id
			}
			syllabus_files {
				file {
					name
				}
				fileId
			}
		}
	}
`;
export const GetSubjectSyllabusDocument = gql`
	query GetSubjectSyllabus($subjectId: Float!) {
		getSubjectSyllabus(subjectId: $subjectId) {
			id
			name
			type
			complementary_bibliography
			bibliography
			learning_goals
			syllabus_structure_children
		}
	}
`;
export const GetSurveysBySubjectDocument = gql`
	query GetSurveysBySubject($caseType: String!, $subjectId: Float!) {
		getSurveysBySubject(caseType: $caseType, subjectId: $subjectId) {
			data {
				id
				dateFrom: date_from
				dateTo: date_to
				name
				topic
				description
				results {
					responseAmmount: response_amount
					responseRate: response_rate
				}
			}
			globalPermissions
		}
	}
`;
export const GetSurveyByIdDocument = gql`
	query GetSurveyById($id: Float!) {
		getCaseByID(id: $id) {
			id
			date_from
			date_to
			description
			subject {
				id
			}
			name
			steps {
				open_field
				step_results {
					id
				}
				step_type {
					id
				}
				created_at
				id
				title
				step_options {
					id
					title
					created_at
				}
			}
		}
	}
`;
export const GetSurveyResultsDocument = gql`
	query GetSurveyResults($surveyId: Float!) {
		getCaseByID(id: $surveyId) {
			id
			name
			date_from
			date_to
			description
			order
			subject {
				id
			}
			steps {
				open_field
				id
				title
				is_math
				created_at
				step_results {
					id
					created_at
					text_answer_results {
						value
					}
					step_option_results {
						step_option {
							id
						}
					}
					out_of_time
					time_spent
					student {
						id
						user {
							id
							first_name
							last_name
							email
						}
					}
				}
				justification {
					description
				}
				step_type {
					id
					name
				}
				step_options {
					id
					is_math
					title
					created_at
				}
			}
		}
	}
`;
export const PostSurveyDocument = gql`
	mutation PostSurvey($data: CreateCaseDto!) {
		postCase(data: $data, caseType: "survey") {
			id
		}
	}
`;
export const DeleteSurveysDocument = gql`
	mutation DeleteSurveys($ids: [Float!]!) {
		deleteCases(ids: $ids, caseType: "survey")
	}
`;
export const DuplicateSurveyDocument = gql`
	mutation DuplicateSurvey($caseId: Float!) {
		duplicateCase(caseId: $caseId) {
			id
		}
	}
`;
export const UpdateSurveyDocument = gql`
	mutation UpdateSurvey($id: Float!, $data: UpdateCaseDto!) {
		putCase(id: $id, data: $data) {
			id
		}
	}
`;
export const GetSurveyStatisticsDocument = gql`
	query GetSurveyStatistics($questionnaireId: Float!) {
		getCaseByID(id: $questionnaireId) {
			id
			dateFrom: date_from
			dateTo: date_to
			description
			name
			order
			topic
			questionnaire_setting {
				id
				aleatory_options
			}
			subject {
				id
			}
			questions: steps {
				files {
					bucket_name
					name
				}
				id
				is_math
				name: title
				step_type {
					id
				}
				justification {
					id
					description
					files {
						bucket_name
						name
					}
				}
				options: step_options {
					id
					is_math
					text: title
				}
				results: step_results {
					id
					created_at
					step_option_results {
						step_option {
							id
						}
					}
					student {
						id
						user {
							id
							first_name
							last_name
						}
					}
				}
			}
		}
	}
`;
export const LoginWebDocument = gql`
	mutation LoginWeb($email: String!, $password: String!, $client: Int!) {
		loginweb(teacher: { email: $email, password: $password, client: $client }) {
			last_name
			national_identification
			id
			teacher {
				profile_picture {
					path
					bucket_name
				}
				id
			}
			phone_number
			first_name
			email
			token
			is_first_use
			...TeacherFields
		}
	}
	${TeacherFieldsFragmentDoc}
`;
export const ConfirmEmailDocument = gql`
	mutation ConfirmEmail($token: String!) {
		confirmEmail(token: $token) {
			id
			first_name
			last_name
			is_active
			is_first_use
			email
			teacher {
				profile_picture {
					path
				}
				id
			}
		}
	}
`;
export const RecoveryNewPasswordDocument = gql`
	mutation RecoveryNewPassword($token: String!, $password: String!) {
		resetPassword(token: $token, password: $password)
	}
`;
export const RegisterDocument = gql`
	mutation Register(
		$first_name: String!
		$last_name: String!
		$email: String!
		$password: String!
		$national_identification: String!
		$phone_number: String!
		$client: Int!
	) {
		register(
			data: {
				first_name: $first_name
				last_name: $last_name
				email: $email
				password: $password
				client: $client
				authType: "EMAIL"
				national_identification: $national_identification
				phone_number: $phone_number
			}
			userType: "teacher"
		) {
			user {
				id
				first_name
				last_name
				is_active
			}
		}
	}
`;
export const CheckIfEmailExistsDocument = gql`
	query CheckIfEmailExists($email: String!) {
		checkEmail(email: $email)
	}
`;
export const GetRoutersForUserDocument = gql`
	query GetRoutersForUser {
		getRoutersForUser
	}
`;
export const SendRecoveryPasswordEmailDocument = gql`
	mutation SendRecoveryPasswordEmail($email: String!) {
		forgotPassword(user: { email: $email })
	}
`;
export const TeacherGoogleLoginDocument = gql`
	mutation TeacherGoogleLogin($code: String!, $client: Float!) {
		teacherGoogleLogin(code: $code, client: $client) {
			id
			first_name
			last_name
			email
			is_first_use
			token
			national_identification
			phone_number
			...TeacherFields
		}
	}
	${TeacherFieldsFragmentDoc}
`;
export const RefreshTeacherTokenDocument = gql`
	mutation RefreshTeacherToken($refreshToken: String!) {
		refreshTeacherToken(refreshToken: $refreshToken) {
			id
			first_name
			last_name
			email
			is_first_use
			token
			national_identification
			phone_number
			...TeacherFields
		}
	}
	${TeacherFieldsFragmentDoc}
`;
export const PutPhotoUserEditDocument = gql`
	mutation PutPhotoUserEdit($changes: UpdateTeacherDto!, $putTeacherId: Int!) {
		putTeacher(changes: $changes, id: $putTeacherId) {
			id
			profile_picture {
				path
				id
				bucket_name
			}
		}
	}
`;
export const UpdateUserDocument = gql`
	mutation UpdateUser($updateUsersId: Int!, $data: UpdateUserDto!) {
		updateUsers(id: $updateUsersId, data: $data) {
			is_first_use
			is_confirmed
			is_active
			id
			first_name
			email
			phone_number
			national_identification
			last_name
			client {
				id
			}
		}
	}
`;
export const IsExpiredTokenDocument = gql`
	mutation IsExpiredToken($token: String!) {
		verifyTokenExpiration(token: $token)
	}
`;

export type SdkFunctionWrapper = <T>(
	action: (requestHeaders?: Record<string, string>) => Promise<T>,
	operationName: string,
	operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
	action,
	_operationName,
	_operationType
) => action();

export function getSdk(
	client: GraphQLClient,
	withWrapper: SdkFunctionWrapper = defaultWrapper
) {
	return {
		GetAiChatSubscriptionData(
			variables?: GetAiChatSubscriptionDataQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetAiChatSubscriptionDataQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetAiChatSubscriptionDataQuery>(
						GetAiChatSubscriptionDataDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetAiChatSubscriptionData',
				'query'
			);
		},
		AddExpense(
			variables: AddExpenseMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<AddExpenseMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<AddExpenseMutation>(AddExpenseDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'AddExpense',
				'mutation'
			);
		},
		PostAcademicProductAnswer(
			variables: PostAcademicProductAnswerMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PostAcademicProductAnswerMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PostAcademicProductAnswerMutation>(
						PostAcademicProductAnswerDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'PostAcademicProductAnswer',
				'mutation'
			);
		},
		DeleteAcademicProductAnswer(
			variables: DeleteAcademicProductAnswerMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DeleteAcademicProductAnswerMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DeleteAcademicProductAnswerMutation>(
						DeleteAcademicProductAnswerDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DeleteAcademicProductAnswer',
				'mutation'
			);
		},
		UpdateAcademicProductAnswer(
			variables: UpdateAcademicProductAnswerMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<UpdateAcademicProductAnswerMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateAcademicProductAnswerMutation>(
						UpdateAcademicProductAnswerDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'UpdateAcademicProductAnswer',
				'mutation'
			);
		},
		GetAcademicObjectsForSubject(
			variables: GetAcademicObjectsForSubjectQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetAcademicObjectsForSubjectQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetAcademicObjectsForSubjectQuery>(
						GetAcademicObjectsForSubjectDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetAcademicObjectsForSubject',
				'query'
			);
		},
		GetSubjectAcademicObjectAnswers(
			variables: GetSubjectAcademicObjectAnswersQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSubjectAcademicObjectAnswersQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSubjectAcademicObjectAnswersQuery>(
						GetSubjectAcademicObjectAnswersDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSubjectAcademicObjectAnswers',
				'query'
			);
		},
		PostBibliographyWithChapters(
			variables: PostBibliographyWithChaptersMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PostBibliographyWithChaptersMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PostBibliographyWithChaptersMutation>(
						PostBibliographyWithChaptersDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'PostBibliographyWithChapters',
				'mutation'
			);
		},
		getBibliography(
			variables?: GetBibliographyQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetBibliographyQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetBibliographyQuery>(
						GetBibliographyDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'getBibliography',
				'query'
			);
		},
		DeleteBibliographyById(
			variables: DeleteBibliographyByIdMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DeleteBibliographyByIdMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DeleteBibliographyByIdMutation>(
						DeleteBibliographyByIdDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DeleteBibliographyById',
				'mutation'
			);
		},
		GetClientSettings(
			variables: GetClientSettingsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetClientSettingsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetClientSettingsQuery>(
						GetClientSettingsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetClientSettings',
				'query'
			);
		},
		GetErrorByCode(
			variables?: GetErrorByCodeQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetErrorByCodeQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetErrorByCodeQuery>(
						GetErrorByCodeDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetErrorByCode',
				'query'
			);
		},
		PostCase(
			variables: PostCaseMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PostCaseMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PostCaseMutation>(PostCaseDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'PostCase',
				'mutation'
			);
		},
		DeleteQuestionnaires(
			variables: DeleteQuestionnairesMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DeleteQuestionnairesMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DeleteQuestionnairesMutation>(
						DeleteQuestionnairesDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DeleteQuestionnaires',
				'mutation'
			);
		},
		PutCase(
			variables: PutCaseMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PutCaseMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PutCaseMutation>(PutCaseDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'PutCase',
				'mutation'
			);
		},
		DuplicateCase(
			variables: DuplicateCaseMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DuplicateCaseMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DuplicateCaseMutation>(
						DuplicateCaseDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DuplicateCase',
				'mutation'
			);
		},
		GetQuestionnairesBySubject(
			variables: GetQuestionnairesBySubjectQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetQuestionnairesBySubjectQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetQuestionnairesBySubjectQuery>(
						GetQuestionnairesBySubjectDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetQuestionnairesBySubject',
				'query'
			);
		},
		GetCaseById(
			variables: GetCaseByIdQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetCaseByIdQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetCaseByIdQuery>(GetCaseByIdDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'GetCaseById',
				'query'
			);
		},
		GetQuestionnaireStatistics(
			variables: GetQuestionnaireStatisticsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetQuestionnaireStatisticsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetQuestionnaireStatisticsQuery>(
						GetQuestionnaireStatisticsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetQuestionnaireStatistics',
				'query'
			);
		},
		GetQuestionnaireNotifications(
			variables: GetQuestionnaireNotificationsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetQuestionnaireNotificationsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetQuestionnaireNotificationsQuery>(
						GetQuestionnaireNotificationsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetQuestionnaireNotifications',
				'query'
			);
		},
		GetCasesBySubjectIdToDashboard(
			variables: GetCasesBySubjectIdToDashboardQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetCasesBySubjectIdToDashboardQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetCasesBySubjectIdToDashboardQuery>(
						GetCasesBySubjectIdToDashboardDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetCasesBySubjectIdToDashboard',
				'query'
			);
		},
		DuplicateCaseToSubject(
			variables: DuplicateCaseToSubjectMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DuplicateCaseToSubjectMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DuplicateCaseToSubjectMutation>(
						DuplicateCaseToSubjectDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DuplicateCaseToSubject',
				'mutation'
			);
		},
		GetAcademicObjectsDashboardReport(
			variables?: GetAcademicObjectsDashboardReportQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetAcademicObjectsDashboardReportQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetAcademicObjectsDashboardReportQuery>(
						GetAcademicObjectsDashboardReportDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetAcademicObjectsDashboardReport',
				'query'
			);
		},
		GetEvaluadosAppDashboardReport(
			variables: GetEvaluadosAppDashboardReportQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetEvaluadosAppDashboardReportQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetEvaluadosAppDashboardReportQuery>(
						GetEvaluadosAppDashboardReportDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetEvaluadosAppDashboardReport',
				'query'
			);
		},
		GetEvaluadosAppDashboardReportHeaders(
			variables?: GetEvaluadosAppDashboardReportHeadersQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetEvaluadosAppDashboardReportHeadersQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetEvaluadosAppDashboardReportHeadersQuery>(
						GetEvaluadosAppDashboardReportHeadersDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetEvaluadosAppDashboardReportHeaders',
				'query'
			);
		},
		GetEvaluadosAppDashboardReportCards(
			variables?: GetEvaluadosAppDashboardReportCardsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetEvaluadosAppDashboardReportCardsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetEvaluadosAppDashboardReportCardsQuery>(
						GetEvaluadosAppDashboardReportCardsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetEvaluadosAppDashboardReportCards',
				'query'
			);
		},
		GetSubjects(
			variables?: GetSubjectsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSubjectsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSubjectsQuery>(GetSubjectsDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'GetSubjects',
				'query'
			);
		},
		PostSubject(
			variables: PostSubjectMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PostSubjectMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PostSubjectMutation>(PostSubjectDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'PostSubject',
				'mutation'
			);
		},
		deleteCaseResultBySubject(
			variables: DeleteCaseResultBySubjectMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DeleteCaseResultBySubjectMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DeleteCaseResultBySubjectMutation>(
						DeleteCaseResultBySubjectDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'deleteCaseResultBySubject',
				'mutation'
			);
		},
		deleteSubjectById(
			variables: DeleteSubjectByIdMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DeleteSubjectByIdMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DeleteSubjectByIdMutation>(
						DeleteSubjectByIdDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'deleteSubjectById',
				'mutation'
			);
		},
		GetSubjectByID(
			variables: GetSubjectByIdQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSubjectByIdQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSubjectByIdQuery>(
						GetSubjectByIdDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSubjectByID',
				'query'
			);
		},
		GetSubjectStudents(
			variables: GetSubjectStudentsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSubjectStudentsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSubjectStudentsQuery>(
						GetSubjectStudentsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSubjectStudents',
				'query'
			);
		},
		CheckIfSubjectCodeExists(
			variables: CheckIfSubjectCodeExistsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<CheckIfSubjectCodeExistsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CheckIfSubjectCodeExistsQuery>(
						CheckIfSubjectCodeExistsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'CheckIfSubjectCodeExists',
				'query'
			);
		},
		UpdateSubject(
			variables: UpdateSubjectMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<UpdateSubjectMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateSubjectMutation>(
						UpdateSubjectDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'UpdateSubject',
				'mutation'
			);
		},
		duplicateSubjectWithContents(
			variables: DuplicateSubjectWithContentsMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DuplicateSubjectWithContentsMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DuplicateSubjectWithContentsMutation>(
						DuplicateSubjectWithContentsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'duplicateSubjectWithContents',
				'mutation'
			);
		},
		GetTeacherSyllabus(
			variables?: GetTeacherSyllabusQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetTeacherSyllabusQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetTeacherSyllabusQuery>(
						GetTeacherSyllabusDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetTeacherSyllabus',
				'query'
			);
		},
		GetSubjectSyllabus(
			variables: GetSubjectSyllabusQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSubjectSyllabusQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSubjectSyllabusQuery>(
						GetSubjectSyllabusDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSubjectSyllabus',
				'query'
			);
		},
		GetSurveysBySubject(
			variables: GetSurveysBySubjectQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSurveysBySubjectQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSurveysBySubjectQuery>(
						GetSurveysBySubjectDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSurveysBySubject',
				'query'
			);
		},
		GetSurveyById(
			variables: GetSurveyByIdQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSurveyByIdQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSurveyByIdQuery>(GetSurveyByIdDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'GetSurveyById',
				'query'
			);
		},
		GetSurveyResults(
			variables: GetSurveyResultsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSurveyResultsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSurveyResultsQuery>(
						GetSurveyResultsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSurveyResults',
				'query'
			);
		},
		PostSurvey(
			variables: PostSurveyMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PostSurveyMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PostSurveyMutation>(PostSurveyDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'PostSurvey',
				'mutation'
			);
		},
		DeleteSurveys(
			variables: DeleteSurveysMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DeleteSurveysMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DeleteSurveysMutation>(
						DeleteSurveysDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DeleteSurveys',
				'mutation'
			);
		},
		DuplicateSurvey(
			variables: DuplicateSurveyMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<DuplicateSurveyMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DuplicateSurveyMutation>(
						DuplicateSurveyDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'DuplicateSurvey',
				'mutation'
			);
		},
		UpdateSurvey(
			variables: UpdateSurveyMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<UpdateSurveyMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateSurveyMutation>(
						UpdateSurveyDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'UpdateSurvey',
				'mutation'
			);
		},
		GetSurveyStatistics(
			variables: GetSurveyStatisticsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetSurveyStatisticsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetSurveyStatisticsQuery>(
						GetSurveyStatisticsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetSurveyStatistics',
				'query'
			);
		},
		LoginWeb(
			variables: LoginWebMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<LoginWebMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<LoginWebMutation>(LoginWebDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'LoginWeb',
				'mutation'
			);
		},
		ConfirmEmail(
			variables: ConfirmEmailMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<ConfirmEmailMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ConfirmEmailMutation>(
						ConfirmEmailDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'ConfirmEmail',
				'mutation'
			);
		},
		RecoveryNewPassword(
			variables: RecoveryNewPasswordMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<RecoveryNewPasswordMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<RecoveryNewPasswordMutation>(
						RecoveryNewPasswordDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'RecoveryNewPassword',
				'mutation'
			);
		},
		Register(
			variables: RegisterMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<RegisterMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<RegisterMutation>(RegisterDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'Register',
				'mutation'
			);
		},
		CheckIfEmailExists(
			variables: CheckIfEmailExistsQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<CheckIfEmailExistsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CheckIfEmailExistsQuery>(
						CheckIfEmailExistsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'CheckIfEmailExists',
				'query'
			);
		},
		GetRoutersForUser(
			variables?: GetRoutersForUserQueryVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<GetRoutersForUserQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetRoutersForUserQuery>(
						GetRoutersForUserDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'GetRoutersForUser',
				'query'
			);
		},
		SendRecoveryPasswordEmail(
			variables: SendRecoveryPasswordEmailMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<SendRecoveryPasswordEmailMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SendRecoveryPasswordEmailMutation>(
						SendRecoveryPasswordEmailDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'SendRecoveryPasswordEmail',
				'mutation'
			);
		},
		TeacherGoogleLogin(
			variables: TeacherGoogleLoginMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<TeacherGoogleLoginMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<TeacherGoogleLoginMutation>(
						TeacherGoogleLoginDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'TeacherGoogleLogin',
				'mutation'
			);
		},
		RefreshTeacherToken(
			variables: RefreshTeacherTokenMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<RefreshTeacherTokenMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<RefreshTeacherTokenMutation>(
						RefreshTeacherTokenDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'RefreshTeacherToken',
				'mutation'
			);
		},
		PutPhotoUserEdit(
			variables: PutPhotoUserEditMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<PutPhotoUserEditMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PutPhotoUserEditMutation>(
						PutPhotoUserEditDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'PutPhotoUserEdit',
				'mutation'
			);
		},
		UpdateUser(
			variables: UpdateUserMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<UpdateUserMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'UpdateUser',
				'mutation'
			);
		},
		IsExpiredToken(
			variables: IsExpiredTokenMutationVariables,
			requestHeaders?: Dom.RequestInit['headers']
		): Promise<IsExpiredTokenMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<IsExpiredTokenMutation>(
						IsExpiredTokenDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'IsExpiredToken',
				'mutation'
			);
		},
	};
}
export type Sdk = ReturnType<typeof getSdk>;
